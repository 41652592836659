import { withRouter } from "react-router-dom";
import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../context/AuthContext';

import Container from 'react-bootstrap/esm/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Rating } from 'react-simple-star-rating'


const ContactPage = () => {
    let { isAuthenticated, authTokens, user } = useContext(AuthContext)

    const [username, SetUsername] = useState(isAuthenticated? user.username : null)
    const [email, SetEmail] = useState(isAuthenticated? user.email : null)
    const [content, SetContent] = useState("")
    const [rating, setRating] = useState(0)

    const handleRating = (rate) => {
        setRating(rate)
    }

    let handleSubmit = (e) => {
        e.preventDefault()
        console.log("TODO: Send review to backend")
    }

    return (
        <Container className="content-container my-4">
            <h1 className="my-2">Give us some feedback</h1>

            <Form onSubmit={ handleSubmit }>
                <Row className="my-4 mx-3">
                    <Col>
                        <Form.Group >
                            <Form.Label>Enter your email</Form.Label>
                            <Form.Control name="email" type="text" style={{ marginBottom: 0 }} onChange={(e) => SetEmail(e.target.value)} value={email}></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Enter your username</Form.Label>
                            <Form.Control name="name" type="text" style={{ marginBottom: 0 }} onChange={(e) => SetUsername(e.target.value)} value={username}></Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                <Form.Group className="my-3 mx-3">
                    <Form.Label>Give us some feedback</Form.Label>
                    <Form.Control as="textarea" name="content" style={{ marginBottom: 0, height: '20vh' }} onChange={(e) => SetContent(e.target.value)} value={content}></Form.Control>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label className="mx-3">Score</Form.Label>
                    <Rating onClick={handleRating} />
                </Form.Group>

                <Form.Group className='text-center mb-3'>
                    <Button className="addPatient-Submit-Button" variant="primary" type="submit">Submit</Button>
                </Form.Group>

            </Form>
        </Container>
    )
}

export default withRouter(ContactPage)