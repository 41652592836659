import React, { useContext, useEffect } from 'react'
import AuthContext from '../context/AuthContext';
import LoginPage from './LoginPage';
import PatientsPage from './PatientsPage';
import history from '../history';
import { withRouter, generatePath } from "react-router-dom";
import { useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode"

const HomePage = () => {
    let { updateToken, user, isAuthenticated, logoutUser, authTokens, forceLogin  } = useContext(AuthContext)

    const location = useLocation()

    if (location.search.split("&").length != 1){
        var patient_id = location.search != "" ? location.search.split("&")[0].split("=")[1] : null
        var photo_id = location.search != "" ? location.search.split("&")[2].split("=")[1] : null
        var photo_date = location.search != "" ? location.search.split("&")[1].split("=")[1] : null
    }
    else{
        console.log(location.search.split("&"))
        var patient_id = location.search != "" ? location.search.split("&")[1].split("=")[1] : null
        var photo_id = null
        var photo_date = null
    }

    let champalimaudLogin = async () => {
        if (patient_id != null) {
            await updateToken(location.hash.split("#")[1]).then(() => {
                history.push({ pathname: generatePath("/patient/:patient_id", { patient_id: patient_id }), state: { id: patient_id } })
            })
        }
        else {
            let data = jwt_decode(location.hash.split("#")[1])
            await updateToken(location.hash.split("#")[1]).then(() => {
                history.push(generatePath("/:username/patients", { username: data.username }))
            })
        } 
    }
    
    useEffect(() => {
        if (location.hash != "") {
            logoutUser(2)
            let data = {
                "access" : location.hash.split("#")[1].split("&")[0],
                "refresh" : location.hash.split("#")[1].split("&")[1]
            }
            if(patient_id != null && photo_date != null && photo_id != null){
                forceLogin(data)
                history.push("/patient/" + patient_id + "/" + photo_date + "/" + photo_id + "/cankado/bcctcore")
            }
            else{
                champalimaudLogin()
            }         
        }
        else if (!isAuthenticated) {
            history.push({ pathname: "/login", state: { patient_id: patient_id, photo_id: photo_id, photo_date: photo_date } })
        }
        else if (user != null){
            history.push(generatePath("/:username/patients", { username: user.username }))
        }
        else {
            history.push("/")
        }
    }, [])

    
}

export default withRouter(HomePage)
