import { withRouter } from "react-router-dom";
import history from '../history';

import Container from 'react-bootstrap/esm/Container';

const AboutPage = () => {
    return (
        <Container className='content-container mt-4'>
            <h1>About WeBCCT.core</h1>
            <p style={{ textAlign: "justify" }}>Breast cancer conservative treatment (BCCT) has been increasingly used over the last few years, as a consequence of its oncological safety and much more acceptable aesthetic outcome.</p>
            <p style={{ textAlign: "justify" }}>Although considerable research has been put into BCCT results and techniques, the aesthetic results are commonly heterogeneous.</p>
            <p style={{ textAlign: "justify" }}>Traditionally, aesthetic evaluation has been performed subjectively by one or more observers. However, this form of assessment has been proved to be not only time consuming but also poorly reproducible. Objective methods of evaluation emerged as a way to overcome the poor reproducibility of subjective assessment.</p>
            <p style={{ textAlign: "justify" }}>The purpose of the WebBCCT.core (Web Breast Cancer Conservative Treatment.cosmetic results) software is to try to simplify and standardise the aesthetical evaluation of BCCT. Its innovative nature consists on the development of a totally objective tool for the quantification of the aesthetic results in BCCT, discriminating between four categories (excellent, good, fair and poor) of overall subjective evaluation.</p>
            <p style={{ textAlign: "justify" }}>The WebBCCT.core Workstation allows the user to select one of the available medical images from an image database. This database may include one to four images of the same patient, comprising front (arms up and down) and lateral views of the patient. The selected images are displayed on the monitor screen of the user computer.
                The WebBCCT.core Workstation integrates a tool to quickly and accurately automate the measurement process of all the well-known indices correlated with the overall aesthetical result of the breast cancer conservative treatment. Users will find WebBCCT.core especially useful for reporting quickly and accurately results that were, up until now, time consuming, requiring the proficiency on several tools.
                A second major functionality of WebBCCT.core Workstation is the capability to translate automatically the set of measures performed on the digital images onto an overall objective classification of the aesthetical result. The objective overall classification outputted by this module constitutes a valuable summary of the aesthetical result, enabling effective comparison among different medical teams. This classification is also reported automatically to the user and saved in a database.</p>

            <p style={{ textAlign: "justify" }}>You may consult the original project information <a class="a-app" href="http://medicalresearch.inescporto.pt/breastresearch/index.php/BCCT.core" target="_blank">here</a>.</p>
        </Container>
    )
}

export default withRouter(AboutPage)