import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav';
import history from '../history';
import { withRouter, generatePath } from "react-router-dom";

const Footer = () => {

  if (window.location.pathname.includes("cankado") || window.location.pathname.includes("logout")) {
    return null
  }
  else return (
    <footer className="site-footer">
        <Navbar className='inesc-bg-dk-green inesc-border-md-green'>
            <Container>
                <img alt="" src="/assets/INESCTEC_logo_green.jpg" width="272" height="82" className="d-inline-block"/>
                <Nav>
                    <Nav.Link className='hover-underline-animation mx-3' onClick={() => history.push(generatePath("/about"))}>About</Nav.Link>
                    <Nav.Link className='hover-underline-animation mx-3' onClick={() => history.push(generatePath("/contact"))}>Contact Us</Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    </footer>
  )
}

export default Footer