import React, { useContext } from 'react'
import AuthContext from '../context/AuthContext'
import history from '../history';
import { withRouter, generatePath } from "react-router-dom";

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav';


const Header = () => {
  let { isAuthenticated, logoutUser, connected, user } = useContext(AuthContext)

  if (window.location.pathname.includes("cankado") || window.location.pathname.includes("logout")) {
    return null
  }
  else return (
    <Navbar className='inesc-bg-md-green inesc-border-dk-green fixed-top'>
      <Navbar.Brand className="px-5" onClick={() => history.push(generatePath("/"))} style={{cursor: 'pointer'}}>
        <img alt="" src="/assets/Logo.png" width="97" height="44" className="d-inline-block" />
      </Navbar.Brand>
      {connected ?
        isAuthenticated ?
          <Navbar.Collapse>
            <Nav className='me-auto'>
              <Nav.Link className='hover-underline-animation mx-3' onClick={() => history.push(generatePath("/"))}>Patients</Nav.Link>
              <Nav.Link className='hover-underline-animation mx-3' onClick={() => history.push(generatePath("/teams"))}>Teams</Nav.Link>
              <Nav.Link className='hover-underline-animation mx-3' onClick={() => history.push({ pathname: generatePath("/:username/dashboard", { username: user.username }) })}>Dashboard</Nav.Link>
              <Nav.Link className='hover-underline-animation mx-3' onClick={() => history.push(generatePath("/breloai"))}>BreloAI</Nav.Link>
            </Nav>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link className='hover-underline-animation mx-3' onClick={() => history.push(generatePath("/morphing"))}>Morphing</Nav.Link>
              <Nav.Link className='hover-underline-animation mx-3' onClick={() => history.push(generatePath("/catalogue"))}>Catalogue</Nav.Link>
              <Nav.Link className='hover-underline-animation mx-3' onClick={() => history.push({ pathname: generatePath("/:username/profile", { username: user.username }) })}>Profile</Nav.Link>
              <Nav.Link id="logout" className='hover-underline-animation mx-3' onClick={() => { logoutUser(1) }}>Logout</Nav.Link>
            </Nav>
          </Navbar.Collapse>
          :
          <Nav className="justify-content-end flex-grow-1 pe-3">
            <Nav.Link className='hover-underline-animation' onClick={() => history.push(generatePath("/login"))}>Authentication</Nav.Link>
          </Nav>
        :
        null
      }
    </Navbar>
  )
}

export default Header
