import React, { useContext, useState } from 'react'
import AuthContext from '../context/AuthContext';
import { withRouter, generatePath } from "react-router-dom";
import history from '../history';

import Button from 'react-bootstrap/esm/Button';
import Container from 'react-bootstrap/esm/Container';
import { Card } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Modal from 'react-bootstrap/Modal';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Collapsible from 'react-collapsible';
import { ListManager } from 'react-beautiful-dnd-grid';
import LoadingOverlay from 'react-loading-overlay';
import Switch from "react-switch";

const CataloguePage = () => {
    let { isAuthenticated, authTokens, user, url } = useContext(AuthContext)

    const [catalogue_id, setCatalogueId] = useState(null)

    const [queryPatient, setQueryPatient] = useState(null)

    const [queryPatientImage, setQueryPatientImage] = useState(null)

    const [generated_exc_good_catalogue, setExcGoodCatalogue] = useState(null)

    const [generated_fair_poor_catalogue, setFairPoorCatalogue] = useState(null)

    const [ordered_exc_good_catalogue, setOrderedExcGoodCatalogue] = useState(null)

    const [ordered_fair_poor_catalogue, setOrderedFairPoorCatalogue] = useState(null)

    const [catalogue_generated, setCatalogueGenerated] = useState(false)


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isActive, setIsActive] = useState(false)

    const [toggleMorphing, setToggleMorphing] = useState(false)
    const handleToggleMorphing = () => {
        setToggleMorphing(!toggleMorphing)
    }

    const [togglePreOrPost, setTogglePreOrPost] = useState(false)
    const handleTogglePreOrPost = () => {
        setTogglePreOrPost(!togglePreOrPost)
    }

    const [showToast, setShowToast] = useState(false);

    let getCatalogue = async () => {
        setIsActive(true)
        let response = await fetch('https://breloai-backend.inesctec.pt/api/catalogue/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access
            },
        })
        let data = await response.json()
        if (response.status === 200) {
            console.log(data)
            setCatalogueId(data['catalogue_id'])
            setQueryPatient(data['query_patient'])
            setQueryPatientImage(data['query_patient_image'])
            setExcGoodCatalogue(data['catalogue_exc_good'])
            setFairPoorCatalogue(data['catalogue_fair_poor'])
            setOrderedExcGoodCatalogue(data['catalogue_exc_good'])
            setOrderedFairPoorCatalogue(data['catalogue_fair_poor'])
            setCatalogueGenerated(true)
        }
        else {
            setCatalogueId(null)
            setQueryPatient(data['query_patient'])
            setQueryPatientImage(data['query_patient_image'])
            setExcGoodCatalogue(0)
            setFairPoorCatalogue(0)
            setOrderedExcGoodCatalogue(0)
            setOrderedFairPoorCatalogue(0)
            refreshCatalogue()
        }
        setIsActive(false)
    }

    let refreshCatalogue = () => {
        setCatalogueId(null)
        setQueryPatient(null)
        setQueryPatientImage(null)
        setExcGoodCatalogue([])
        setFairPoorCatalogue([])
        setOrderedExcGoodCatalogue([])
        setOrderedFairPoorCatalogue([])

        getCatalogue()
    }

    let submitCatalogue = async () => {
        let response = await fetch('https://breloai-backend.inesctec.pt/api/catalogue/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access
            },
            body: JSON.stringify({
                'catalogue_id': catalogue_id,
                'catalogue_exc_good': ordered_exc_good_catalogue,
                'catalogue_fair_poor': ordered_fair_poor_catalogue
            }),
        })
        if (response.status === 200) {
            setCatalogueId(null)
            setQueryPatient(null)
            setQueryPatientImage(null)
            setExcGoodCatalogue(null)
            setFairPoorCatalogue(null)
            setOrderedExcGoodCatalogue(null)
            setOrderedFairPoorCatalogue(null)
            setCatalogueGenerated(false)
            handleClose()
        }
    }

    let getClassificationText = (classification) => {
        if (classification === null) {
            return (
                <div>
                    <h5>Unknown</h5>
                </div>
            )
        }
        else if (classification === 1) {
            return (
                <div>
                    <h5>Excellent</h5>
                </div>
            )
        }
        else if (classification === 2) {
            return (
                <div>
                    <h5>Good</h5>
                </div>
            )
        }
        else if (classification === 3) {
            return (
                <div>
                    <h5>Fair</h5>
                </div>
            )
        }
        else if (classification === 4) {
            return (
                <div>
                    <h5>Poor</h5>
                </div>
            )
        }
    }

    let handleOnDragEndExcGood = (sourceIndex, destinationIndex) => {
        const items = Array.from(ordered_exc_good_catalogue)
        const [reorderItem] = items.splice(sourceIndex, 1)
        items.splice(destinationIndex, 0, reorderItem)

        setOrderedExcGoodCatalogue(items)
    }

    let handleOnDragEndFairPoor = (sourceIndex, destinationIndex) => {
        const items = Array.from(ordered_fair_poor_catalogue)
        const [reorderItem] = items.splice(sourceIndex, 1)
        items.splice(destinationIndex, 0, reorderItem)

        setOrderedFairPoorCatalogue(items)
    }

    let downloadCatalogues = async () => {
        let response = await fetch('https://breloai-backend.inesctec.pt/api/downloadCatalogues', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access
            },
        })
        let data = await response
        if (response.status === 200) {
            setShowToast(true)
        }
    }

    return (
        <Container style={{ minWidth: '100vw' }}>
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => history.push(generatePath("/"))}>Patients</Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Catalogue Page
                </Breadcrumb.Item>
            </Breadcrumb>

            <ToastContainer className="mt-5" position="top-center">
                <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide bg='success'>
                    <Toast.Header>
                        <strong className="me-auto">Download Successful</strong>
                    </Toast.Header>
                    <Toast.Body>
                        Go to the '/media/csvs' in the CINDERELLA NAS.
                    </Toast.Body>
                </Toast>
            </ToastContainer>

            {
                user.is_staff ?
                    <Container>
                        <div className='addPatient-div'>
                            <Button variant="outline-primary" className="addPatient-button" onClick={() => downloadCatalogues()}><i className="fa fa-download" aria-hidden="true"></i> Download Catalogues Data</Button>
                        </div>
                    </Container> :
                    null
            }

            <div className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                <span className='mx-2'><strong>Pre-Pre Images</strong></span>
                <Switch onChange={handleTogglePreOrPost} checked={togglePreOrPost} checkedIcon={false} uncheckedIcon={false} onColor={"#888"} />
                <span className='mx-2'><strong>Post-Post Images</strong></span>
            </div>

            <div className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                <Card style={{ width: '20vw' }}>
                    <Card.Img variant="top" src={queryPatient === null ? "https://breloai-backend.inesctec.pt/media/default.jpeg" : "https://breloai-backend.inesctec.pt" + queryPatientImage} width="250" height="250" />
                </Card>
            </div>
            {
                queryPatient !== null ?
                    <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                        <span className='text-center'><strong>
                            {queryPatient.surgery_type === 1 ? "C1 - Conservative Surgery" :
                                queryPatient.surgery_type === 2 ? "C2 - Conservative Surgery with bilateral reduction" :
                                    queryPatient.surgery_type === 3 ? "C3 - Conservative Surgery with LD or LICAC/TDAP flaps" :
                                        queryPatient.surgery_type === 4 ? "C4 - Conservative Surgery with bilateral implants" :
                                            queryPatient.surgery_type === 5 ? "M1 - Mastectomy with unilateral reconstruction with implant" :
                                                queryPatient.surgery_type === 6 ? "M2 - Mastectomy with unilateral reconstruction with autologous flap" :
                                                    queryPatient.surgery_type === 7 ? "M3 - Mastectomy with bilateral reconstruction with implants" :
                                                        queryPatient.surgery_type === 8 ? "M4 - Mastectomy with bilateral reconstruction with autologous flaps" :
                                                            queryPatient.surgery_type === 9 ? "M5 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with implant (augmentation)" :
                                                                queryPatient.surgery_type === 10 ? "M6 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with reduction" :
                                                                    queryPatient.surgery_type === 11 ? "M7 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with reduction" :
                                                                        "M8 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with implant (augmentation)"}
                        </strong>
                        </span>
                    </div>
                    :
                    null
            }
            <h4 className='text-center'>Order the catalogues below from left to right and top to bottom!</h4>
            {/*<div style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                <span className='mx-2'><strong>Toggle Image Morphing</strong></span>
                <Switch onChange={handleToggleMorphing} checked={toggleMorphing} />
            </div>*/}
            <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', }} className='my-2'>
                <span className='mx-2'><strong>Refresh Catalogue</strong></span>
                <Button onClick={refreshCatalogue} variant="success"><i className="fa-solid fa-arrows-rotate"></i></Button>
            </div>
            <LoadingOverlay active={isActive} spinner>
                <Container className='mb-4 mt-4'>
                    <Collapsible className="mt-5 mb-5" trigger="Show/Hide Excellent and Good Catalogue" open={true}>
                        <hr style={{ height: 6, backgroundColor: 'black', color: 'black', borderRadius: 5 }}></hr>
                        {
                            ordered_exc_good_catalogue !== null ?
                                ordered_exc_good_catalogue.length === 0 ?
                                    <h4 className='text-center mb-4'>No results found for Excellent and Good classifications.</h4>
                                    :
                                    <ListManager
                                        items={ordered_exc_good_catalogue}
                                        direction="horizontal"
                                        maxItems={4}
                                        render={(patient) => {
                                            return (
                                                <Card className='text-center mx-4 my-2'>
                                                    <Card.Body>
                                                        <Card.Img variant='bottom' src={togglePreOrPost === false ? 'https://breloai-backend.inesctec.pt' + patient.pre_surgery_photo : 'https://breloai-backend.inesctec.pt' + patient.post_surgery_photo} width="250" height="250"></Card.Img>
                                                        {/*<Card.Text className="text-center card-header-text" as="h5"> {getClassificationText(patient.classification)} </Card.Text>*/}
                                                    </Card.Body>
                                                </Card>
                                            );
                                        }}
                                        onDragEnd={handleOnDragEndExcGood}
                                    />
                                :
                                null
                        }
                        <hr style={{ height: 6, backgroundColor: 'black', color: 'black', borderRadius: 5 }}></hr>
                    </Collapsible>
                    <Collapsible className="mt-5" trigger="Show/Hide Fair and Poor Catalogue" open={true}>

                        <hr style={{ height: 6, backgroundColor: 'black', color: 'black', borderRadius: 5 }}></hr>
                        {
                            ordered_fair_poor_catalogue !== null ?
                                ordered_fair_poor_catalogue.length === 0 ?
                                    <h4 className='text-center mb-4'>No results found for Fair and Poor classifications.</h4>
                                    :
                                    <ListManager
                                        items={ordered_fair_poor_catalogue}
                                        direction="horizontal"
                                        maxItems={4}
                                        render={(patient) => {
                                            return (
                                                <Card className='text-center mx-4 my-2'>
                                                    <Card.Body>
                                                        <Card.Img variant='bottom' src={togglePreOrPost === false ? 'https://breloai-backend.inesctec.pt' + patient.pre_surgery_photo : 'https://breloai-backend.inesctec.pt' + patient.post_surgery_photo} width="250" height="250"></Card.Img>
                                                        {/*<Card.Text className="text-center card-header-text" as="h5"> {getClassificationText(patient.classification)} </Card.Text>*/}
                                                    </Card.Body>
                                                </Card>
                                            );
                                        }}
                                        onDragEnd={handleOnDragEndFairPoor}
                                    />
                                :
                                null
                        }
                        <hr style={{ height: 6, backgroundColor: 'black', color: 'black', borderRadius: 5 }}></hr>
                    </Collapsible>
                </Container>
            </LoadingOverlay>

            <Container className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                {
                    catalogue_generated ?
                        <Button className='FilledButton my-3 mx-1' onClick={handleShow}><i className="fa-solid fa-upload"></i> Submit Catalogue </Button>
                        :
                        <Button className='FilledButton my-3 mx-1' onClick={getCatalogue}><i className="fa-solid fa-book-open"></i> Generate Catalogue </Button>
                }

                {
                    generated_exc_good_catalogue === ordered_exc_good_catalogue && generated_fair_poor_catalogue === ordered_fair_poor_catalogue ?
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Submit Catalogue</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                You have not made any changes to the catalogues. Are you sure you want to proceed?
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button variant="danger" onClick={submitCatalogue}>
                                    Confirm
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        :
                        generated_exc_good_catalogue === ordered_exc_good_catalogue && generated_fair_poor_catalogue !== ordered_fair_poor_catalogue ?
                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Submit Catalogue</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    You have not made any changes to the Excellent and Good catalogue. Are you sure you want to proceed?
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancel
                                    </Button>
                                    <Button variant="danger" onClick={submitCatalogue}>
                                        Confirm
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            :
                            generated_exc_good_catalogue !== ordered_exc_good_catalogue && generated_fair_poor_catalogue === ordered_fair_poor_catalogue ?
                                <Modal show={show} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Submit Catalogue</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        You have not made any changes to the Fair and Poor catalogue. Are you sure you want to proceed?
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Cancel
                                        </Button>
                                        <Button variant="danger" onClick={submitCatalogue}>
                                            Confirm
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                :
                                <Modal show={show} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Submit Catalogue</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        Are you sure you want to proceed?
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Cancel
                                        </Button>
                                        <Button variant="danger" onClick={submitCatalogue}>
                                            Confirm
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                }
            </Container>
        </Container >

    )
}

export default withRouter(CataloguePage)