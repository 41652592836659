import React, { useContext, useState } from 'react'
import AuthContext from '../context/AuthContext'
import { withRouter, generatePath } from "react-router-dom";
import history from '../history';

import Container from 'react-bootstrap/esm/Container'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import Collapsible from 'react-collapsible';
import Select from 'react-select'

const AddPatientPage = () => {
  let { authTokens, url } = useContext(AuthContext)
  const [hasProject, setHasProject] = useState(false)
  const [errors, setErrors] = useState({})

  const [projects, setProjetcs] = useState([])

  let options = [
    { value: '1', label: 'CINDERELLA' },
    { value: '0', label: 'Other' }
  ]

  const options2 = [
    { value: "1", label: 'Left' },
    { value: "2", label: 'Right' },
]

  const [institutional_number, setInstitutionalNumber] = useState(null)
  const [birthday, setBirthday] = useState(null)
  const [height, setHeight] = useState(null)
  const [weight, setWeight] = useState(null)
  const [bra_size, setBraSize] = useState(null)
  const [bra_cup, setBraCup] = useState(null)
  const [cancer_side, setCancerSide] = useState([])

  let addPatient = async (e) => {
    e.preventDefault()

    let response = await fetch('https://breloai-backend.inesctec.pt/api/addPatient/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authTokens?.access
      },
      body: JSON.stringify({
        'institutional_number': institutional_number,
        'birthday': birthday,
        'has_project': hasProject,
        'projects': projects,
        'height': height,
        'weight': weight,
        'bra_size': bra_size,
        'bra_cup': bra_cup
      })
    })
    let data = await response.json()
    if (response.status == 200) {
      history.push({ pathname: generatePath("/patient/:id", { id: data }), state: { id: data, } })
    }
    else if (response.status == 400 && data.institutional_number) {
      setErrors({ institutional_number: data.institutional_number })
    }
    else if (response.status == 400 && data.birthday) {
      setErrors({ birthday: data.birthday })
    }

  }

  return (
    <Container style={{ minWidth: "80vw" }}>

      <Breadcrumb className='mt-2'>
        <Breadcrumb.Item onClick={() => history.push(generatePath("/"))}>Patients</Breadcrumb.Item>
        <Breadcrumb.Item active>
          Add Patient
        </Breadcrumb.Item>
      </Breadcrumb>

      <div className='my-auto'>

        <h2 className='text-center my-3'><strong>Create a new Patient</strong></h2>

        <Form className='addPatientForm' onSubmit={addPatient}>

          <Form.Group className="mx-0">

            <Row className='my-3'>

              <Col>
                <Form.Group>
                  <Form.Label>Institutional Number</Form.Label>
                  <Form.Control name="institutional_number" type="text" onChange={(e) => setInstitutionalNumber(e.target.value)} isInvalid={!!errors.institutional_number} />
                  <Form.Control.Feedback type="invalid">{errors.institutional_number}</Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group>
                  <Form.Label>Birthday</Form.Label>
                  <Form.Control name="birthday" type="date" onChange={(e) => setBirthday(e.target.value)} isInvalid={!!errors.birthday} />
                  <Form.Control.Feedback type="invalid">{errors.birthday}</Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group style={{ display: "flex", justifyContent: "center" }} className='mt-4'>
                  <Form.Check type="checkbox" name="project" label="Is part of a project?" onClick={() => { setHasProject(current => !current); }} defaultChecked={hasProject} />
                </Form.Group>
              </Col>

              <Col className='mt-4 mb-0'>
                <Select isDisabled={!hasProject} isMulti options={options} className="basic-multi-select" classNamePrefix="select" name={"projects"}
                  onChange={(e) => {
                    projects.length = 0
                    e.map((aux) => { projects.push(parseInt(aux.value)) })
                  }}/>
              </Col>
            </Row>

            <hr style={{ height: 6, backgroundColor: 'black', color: 'black', borderRadius: 5 }}></hr>

            <Row className='my-3'>
              <Col>
                <Form.Group>
                  <Form.Label>Height</Form.Label>
                  <Form.Control name="height" type="text" onChange={(e) => setHeight(e.target.value)} />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group>
                  <Form.Label>Weight</Form.Label>
                  <Form.Control name="weight" type="text" onChange={(e) => setWeight(e.target.value)} />
                </Form.Group>
              </Col>
            </Row>

            <Row className='my-3'>
              <Col>
                <Form.Group>
                  <Form.Label>Bra Size</Form.Label>
                  <Form.Control name="bra_size" type="text" onChange={(e) => setBraSize(e.target.value)} />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group>
                  <Form.Label>Bra Cup</Form.Label>
                  <Form.Control name="bra_cup" type="text" onChange={(e) => setBraCup(e.target.value)} />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className='text-center'>
              <Button className="addPatient-Submit-Button" variant="primary" type="submit">Create</Button>
            </Form.Group>
          </Form.Group>
        </Form>
      </div>


    </Container>
  )
}

export default withRouter(AddPatientPage)
