import React, { useEffect, useState, useRef } from 'react'
import Container from 'react-bootstrap/esm/Container';
import { withRouter } from "react-router-dom";
import axios from 'axios'

import Button from 'react-bootstrap/esm/Button';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Table from 'react-bootstrap/Table';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import LoadingOverlay from 'react-loading-overlay';
import { FileUploader } from "react-drag-drop-files";
import Collapsible from 'react-collapsible';
import ImageGallery from 'react-image-gallery';

import * as createjs from 'createjs-module';
import Papa from 'papaparse';


const WebBcctCorePage = () => {
    let url = null
    if (window.location.href.includes("localhost")) {
        url = "localhost"
    }
    else {
        url = "194.117.27.135"
    }
    var smoothArrayJs = require("smooth-array-js")

    const [isActive, setIsActive] = useState(false)
    const [showFeedback, setShowFeedback] = useState(false);
    const [showModal, setShowModal] = useState(true);

    var canvas, stage;
    var update = false;
    //const [update, setUpdate] = useState(true)
    var nMarkers = 38;

    // Indices of the Keypoints in the UI
    var leftNippleIdx = 0;
    var rightNippleIdx = 1;
    var markScaleIdx = 2;
    var sternalNotchIdx = 3;
    var leftContourIdxStart = 4;
    var leftContourIdxEnd = 20;
    var rightContourIdxStart = 21;
    var rightContourIdxEnd = 37;

    //
    const [markersVec, set_markersVec] = useState([])
    var markersText = ["Left Nipple", "Right Nipple", "Scale Mark", "Sternal Notch",];
    var featurePanel;
    let contours;
    var backgroundImg;
    var bitmap_bck;
    var loaded_img;
    var container;
    var BRA;
    var txtBRA;
    var zoomStatus = 0;
    var stage = null;

    const [bra_value, set_bra_value] = useState(null)
    const [lbc_value, set_lbc_value] = useState(null)
    const [unr_value, set_unr_value] = useState(null)
    const [bce_value, set_bce_value] = useState(null)
    const [bcd_value, set_bcd_value] = useState(null)
    const [bad_value, set_bad_value] = useState(null)
    const [bod_value, set_bod_value] = useState(null)
    const [p_bra_value, set_p_bra_value] = useState(null)
    const [p_lbc_value, set_p_lbc_value] = useState(null)
    const [p_unr_value, set_p_unr_value] = useState(null)
    const [p_bce_value, set_p_bce_value] = useState(null)
    const [p_bcd_value, set_p_bcd_value] = useState(null)
    const [p_bad_value, set_p_bad_value] = useState(null)
    const [p_bod_value, set_p_bod_value] = useState(null)
    const [cx2l_value, set_cx2l_value] = useState(null)
    const [cx2a_value, set_cx2a_value] = useState(null)
    const [cx2b_value, set_cx2b_value] = useState(null)
    const [cx2lab_value, set_cx2lab_value] = useState(null)
    const [cemdl_value, set_cemdl_value] = useState(null)
    const [cemda_value, set_cemda_value] = useState(null)
    const [cemdb_value, set_cemdb_value] = useState(null)
    const [cemdlab_value, set_cemdlab_value] = useState(null)
    const [classification_value, set_classification_value] = useState(null)
    const [scale_value, set_scale_value] = useState(null)


    let sx2l_value = null
    let sx2a_value = null
    let sx2b_value = null
    let sx2lab_value = null
    let semdl_value = null
    let semda_value = null
    let semdb_value = null
    let semdlab_value = null
    let l_breast_contour_len = null
    let r_breast_contour_len = null
    let l_breast_area = null
    let r_breast_area = null
    let l_nipple_ifdni = null
    let r_nippple_ifdni = null
    let l_nipple_hpsn_x1 = null
    let r_nipple_hpsn_x2 = null
    let l_nipple_vpsn_y1 = null
    let r_nipple_vpsn_y2 = null


    //const aestheticClassificationValue = ReactDOM.createRoot(document.getElementById('aestheticClassificationValue'));

    const init = (newCanvas, index, keypoints) => {
        contours = null;
        backgroundImg = null;
        bitmap_bck = null;
        loaded_img = false;
        container = null;
        markersVec.splice(0, markersVec.length)

        newCanvas != null ? canvas = newCanvas : canvas = document.getElementById("bcctCanvas")

        stage = new createjs.Stage(canvas);

        // enable touch interactions if supported on the current device:
        createjs.Touch.enable(stage);
        // enabled mouse over / out events
        stage.enableMouseOver(10);
        stage.mouseMoveOutside = true; // keep tracking the mouse even when it leaves the canvas
        loaded_img = false;
        container = new createjs.Container();
        for (var i = markersText.length; i < nMarkers; i++) {
            if (i == leftContourIdxStart) {
                markersText.push("Left EP");
            }
            else if (i == leftContourIdxEnd) {
                markersText.push("Left MP");
            }
            else if (i == rightContourIdxStart) {
                markersText.push("Right EP");
            }
            else if (i == rightContourIdxEnd) {
                markersText.push("Right MP");
            }
            else {
                markersText.push("");
            }
        }

        stage.addChild(container);
        container.removeAllChildren();

        bitmap_bck = imagesList[index]
        container.addChild(bitmap_bck);
        bitmap_bck.originalScale = 1.0;
        bitmap_bck.scale = 1.0;

        canvas.height = bitmap_bck.image.height * bitmap_bck.originalScale;
        canvas.width = bitmap_bck.image.width * bitmap_bck.originalScale;


        // create and populate the screen with markers:
        for (i = 0; i < nMarkers; i++) {
            //container = new createjs.Container();
            //markersVec.push(new createjs.Shape());
            markersVec.push(new createjs.Container());
            var shapeMarker = new createjs.Shape();
            var g = shapeMarker.graphics;
            g.beginFill("#FF0000");
            shapeMarker.alpha = 0.25;
            if (((i > leftContourIdxStart && i < leftContourIdxEnd)) || (i > rightContourIdxStart && i < rightContourIdxEnd)) {
                g.beginFill("#DFDFDF");
                shapeMarker.alpha = 0.5;
            }
            var radius = canvas.height / 40;
            g.drawCircle(0, 0, radius);
            g.endFill();

            markersVec[i].addChild(shapeMarker);
            shapeMarker = new createjs.Shape();
            g = shapeMarker.graphics;
            g.beginFill("#000000");
            g.drawCircle(0, 0, 1);
            g.endFill();
            markersVec[i].addChild(shapeMarker);

            container.addChild(markersVec[i]);
            markersVec[i].scale = markersVec[i].originalScale = 1;//Math.random() * 0.4 + 0.6;
            markersVec[i].name = "bmp_" + i;
            markersVec[i].cursor = "pointer";

            var txt = new createjs.Text();
            txt.font = "bold 5vh Dorsa";
            txt.color = "#000000";
            txt.textAlign = "center";
            txt.text = markersText[i];
            markersVec[i].addChild(txt);

            // using "on" binds the listener to the scope of the currentTarget by default
            // in this case that means it executes in the scope of the button.
            markersVec[i].on("mousedown", function (evt) {
                this.parent.addChild(this);
                this.offset = { x: this.x - evt.stageX, y: this.y - evt.stageY };
            });
            // the pressmove event is dispatched when the mouse moves after a mousedown on the target until the mouse is released.
            markersVec[i].on("pressmove", function (evt) {
                this.x = evt.stageX + this.offset.x;
                this.y = evt.stageY + this.offset.y;
                // indicate that the stage should be updated on the next tick:
                update = true;
                //setUpdate(true)
            });
            markersVec[i].on("rollover", function (evt) {
                this.scale = this.originalScale * 1.2;
                update = true;
                //setUpdate(true)
            });
            markersVec[i].on("rollout", function (evt) {
                this.scale = this.originalScale;
                update = true;
                savePoints(index, markersVec)
                //setUpdate(true)
            });
        }


        // Scale Mark
        markersVec[markScaleIdx].x = 0.5 * canvas.width;
        markersVec[markScaleIdx].y = 2.0 * canvas.height / 3;

        markersVec[sternalNotchIdx].x = parseInt(keypoints[68])
        markersVec[sternalNotchIdx].y = parseInt(keypoints[69])

        markersVec[leftNippleIdx].x = parseInt(keypoints[72])
        markersVec[leftNippleIdx].y = parseInt(keypoints[73])

        markersVec[rightNippleIdx].x = parseInt(keypoints[70])
        markersVec[rightNippleIdx].y = parseInt(keypoints[71])

        markersVec[leftContourIdxStart].x = parseInt(keypoints[34])
        markersVec[leftContourIdxStart].y = parseInt(keypoints[35])

        markersVec[leftContourIdxStart + 1].x = parseInt(keypoints[36])
        markersVec[leftContourIdxStart + 1].y = parseInt(keypoints[37])

        markersVec[leftContourIdxStart + 2].x = parseInt(keypoints[38])
        markersVec[leftContourIdxStart + 2].y = parseInt(keypoints[39])

        markersVec[leftContourIdxStart + 3].x = parseInt(keypoints[40])
        markersVec[leftContourIdxStart + 3].y = parseInt(keypoints[41])

        markersVec[leftContourIdxStart + 4].x = parseInt(keypoints[42])
        markersVec[leftContourIdxStart + 4].y = parseInt(keypoints[43])

        markersVec[leftContourIdxStart + 5].x = parseInt(keypoints[44])
        markersVec[leftContourIdxStart + 5].y = parseInt(keypoints[45])

        markersVec[leftContourIdxStart + 6].x = parseInt(keypoints[46])
        markersVec[leftContourIdxStart + 6].y = parseInt(keypoints[47])

        markersVec[leftContourIdxStart + 7].x = parseInt(keypoints[48])
        markersVec[leftContourIdxStart + 7].y = parseInt(keypoints[49])

        markersVec[leftContourIdxStart + 8].x = parseInt(keypoints[50])
        markersVec[leftContourIdxStart + 8].y = parseInt(keypoints[51])

        markersVec[leftContourIdxStart + 9].x = parseInt(keypoints[52])
        markersVec[leftContourIdxStart + 9].y = parseInt(keypoints[53])

        markersVec[leftContourIdxStart + 10].x = parseInt(keypoints[54])
        markersVec[leftContourIdxStart + 10].y = parseInt(keypoints[55])

        markersVec[leftContourIdxStart + 11].x = parseInt(keypoints[56])
        markersVec[leftContourIdxStart + 11].y = parseInt(keypoints[57])

        markersVec[leftContourIdxStart + 12].x = parseInt(keypoints[58])
        markersVec[leftContourIdxStart + 12].y = parseInt(keypoints[59])

        markersVec[leftContourIdxStart + 13].x = parseInt(keypoints[60])
        markersVec[leftContourIdxStart + 13].y = parseInt(keypoints[61])

        markersVec[leftContourIdxStart + 14].x = parseInt(keypoints[62])
        markersVec[leftContourIdxStart + 14].y = parseInt(keypoints[63])

        markersVec[leftContourIdxStart + 15].x = parseInt(keypoints[64])
        markersVec[leftContourIdxStart + 15].y = parseInt(keypoints[65])

        markersVec[leftContourIdxEnd].x = parseInt(keypoints[66])
        markersVec[leftContourIdxEnd].y = parseInt(keypoints[67])

        markersVec[rightContourIdxStart].x = parseInt(keypoints[0])
        markersVec[rightContourIdxStart].y = parseInt(keypoints[1])

        markersVec[rightContourIdxStart + 1].x = parseInt(keypoints[2])
        markersVec[rightContourIdxStart + 1].y = parseInt(keypoints[3])

        markersVec[rightContourIdxStart + 2].x = parseInt(keypoints[4])
        markersVec[rightContourIdxStart + 2].y = parseInt(keypoints[5])

        markersVec[rightContourIdxStart + 3].x = parseInt(keypoints[6])
        markersVec[rightContourIdxStart + 3].y = parseInt(keypoints[7])

        markersVec[rightContourIdxStart + 4].x = parseInt(keypoints[8])
        markersVec[rightContourIdxStart + 4].y = parseInt(keypoints[9])

        markersVec[rightContourIdxStart + 5].x = parseInt(keypoints[10])
        markersVec[rightContourIdxStart + 5].y = parseInt(keypoints[11])

        markersVec[rightContourIdxStart + 6].x = parseInt(keypoints[12])
        markersVec[rightContourIdxStart + 6].y = parseInt(keypoints[13])

        markersVec[rightContourIdxStart + 7].x = parseInt(keypoints[14])
        markersVec[rightContourIdxStart + 7].y = parseInt(keypoints[15])

        markersVec[rightContourIdxStart + 8].x = parseInt(keypoints[16])
        markersVec[rightContourIdxStart + 8].y = parseInt(keypoints[17])

        markersVec[rightContourIdxStart + 9].x = parseInt(keypoints[18])
        markersVec[rightContourIdxStart + 9].y = parseInt(keypoints[19])

        markersVec[rightContourIdxStart + 10].x = parseInt(keypoints[20])
        markersVec[rightContourIdxStart + 10].y = parseInt(keypoints[21])

        markersVec[rightContourIdxStart + 11].x = parseInt(keypoints[22])
        markersVec[rightContourIdxStart + 11].y = parseInt(keypoints[23])

        markersVec[rightContourIdxStart + 12].x = parseInt(keypoints[24])
        markersVec[rightContourIdxStart + 12].y = parseInt(keypoints[25])

        markersVec[rightContourIdxStart + 13].x = parseInt(keypoints[26])
        markersVec[rightContourIdxStart + 13].y = parseInt(keypoints[27])

        markersVec[rightContourIdxStart + 14].x = parseInt(keypoints[28])
        markersVec[rightContourIdxStart + 14].y = parseInt(keypoints[29])

        markersVec[rightContourIdxStart + 15].x = parseInt(keypoints[30])
        markersVec[rightContourIdxStart + 15].y = parseInt(keypoints[31])

        markersVec[rightContourIdxEnd].x = parseInt(keypoints[32])
        markersVec[rightContourIdxEnd].y = parseInt(keypoints[33])


        contours = new createjs.Shape();
        container.addChild(contours);

        loaded_img = true;
        update = true;



        document.getElementById("resetZoom").addEventListener("click", returnToInitialZoomState)
        document.getElementById("zoomIn").addEventListener("click", () => zoom(2.0))
        document.getElementById("zoomOut").addEventListener("click", () => zoom(0.5))
        createjs.Ticker.addEventListener("tick", tick);

        document.getElementById("myRange3").addEventListener("mouseup", (e) => updateMarkerSizes())

        BRA = 0;
    }

    function updateMarkerSizes() {
        markersVec.forEach(marker => {
            marker.children[0].scaleX = document.getElementById("myRange3").value / 2.5
            marker.children[0].scaleY = document.getElementById("myRange3").value / 2.5
            marker.children[1].scaleX = document.getElementById("myRange3").value
            marker.children[1].scaleY = document.getElementById("myRange3").value
            marker.children[2].font = "bold " + document.getElementById("myRange3").value * 2.5 + "vh Dorsa"
        })
        update = true
    }

    function tick(event) {
        // this set makes it so the stage only re-renders when an event handler indicates a change has happened.
        if (update) {
            //update = false; // only update once
            drawContour();
            // computeMeasures
            var BRAaux1 = Math.abs(markersVec[leftNippleIdx].x - markersVec[sternalNotchIdx].x);
            var BRAaux2 = Math.abs(markersVec[rightNippleIdx].x - markersVec[sternalNotchIdx].x);
            var BRAaux3 = Math.abs(markersVec[leftNippleIdx].y - markersVec[sternalNotchIdx].y);
            var BRAaux4 = Math.abs(markersVec[rightNippleIdx].y - markersVec[sternalNotchIdx].y);
            var BRAaux5 = Math.sqrt((BRAaux1 - BRAaux2) * (BRAaux1 - BRAaux2) + (BRAaux3 - BRAaux4) * (BRAaux3 - BRAaux4));
            var scale = 25.0 / Math.abs(markersVec[markScaleIdx].y - markersVec[sternalNotchIdx].y);
            BRA = BRAaux5 * scale;
            //txtBRA.text = "BRA: " + BRA.toFixed(3);
            //document.getElementById("BRA").innerHTML = BRA.toFixed(3);
            stage.update(event);
            update = false;
        }
    }

    function drawContour() {
        var spline_res = 64;
        contours.graphics.clear();
        contours.graphics.setStrokeStyle(document.getElementById("myRange3").value * 3).beginStroke("rgba(0,0,0,1)");

        var points = [];
        for (var i = leftContourIdxStart; i <= leftContourIdxEnd; i++) {
            var aux_ = [];
            aux_.push(markersVec[i].x);
            aux_.push(markersVec[i].y);
            points.push(aux_);
        }
        var path = smoothArrayJs.Smooth(points, {
            method: smoothArrayJs.Smooth.METHOD_CUBIC,
            cubicTension: smoothArrayJs.Smooth.CUBIC_TENSION_CATMULL_ROM
        });

        var aux2 = path(0);
        contours.graphics.moveTo(aux2[0], aux2[1]);
        for (var i = 0; i <= points.length - 1; i += (1.0 / spline_res)) {
            aux2 = path(i);
            contours.graphics.lineTo(aux2[0], aux2[1]);
        }


        points = [];
        for (var i = rightContourIdxStart; i <= rightContourIdxEnd; i++) {
            var aux = [];
            aux.push(markersVec[i].x);
            aux.push(markersVec[i].y);
            points.push(aux);
        }
        path = smoothArrayJs.Smooth(points, {
            method: smoothArrayJs.Smooth.METHOD_CUBIC,
            cubicTension: smoothArrayJs.Smooth.CUBIC_TENSION_CATMULL_ROM
        });

        var aux = path(0);
        contours.graphics.moveTo(aux[0], aux[1]);
        for (var i = 0; i <= points.length - 1; i += (1.0 / spline_res)) {
            aux = path(i);
            contours.graphics.lineTo(aux[0], aux[1]);
        }


        contours.graphics.endStroke();
    }

    var zoom = (factor) => {
        if (!loaded_img)
            return;
        // if (zoomStatus == 0 && factor < 1.0) {
        // return;
        // }
        bitmap_bck.scale *= factor;
        bitmap_bck.scaleX *= factor;
        bitmap_bck.scaleY *= factor;
        canvas.height = bitmap_bck.image.height * bitmap_bck.scale;
        canvas.width = bitmap_bck.image.width * bitmap_bck.scale;
        //change pos of markers
        for (var i = 0; i < nMarkers; i++) {
            markersVec[i].x *= factor;
            markersVec[i].y *= factor;
        }
        featurePanel.x *= factor;
        featurePanel.y *= factor;
        update = true;
        //setUpdate(true)
        if (factor < 1.0) {
            zoomStatus -= 1;
        } else if (factor > 1.0) {
            zoomStatus += 1;
        } else {
            zoomStatus += 0;
        }
    }

    var returnToInitialZoomState = () => {
        var factor = null
        if (zoomStatus < 1.0) {
            factor = Math.pow(2.0, Math.abs(zoomStatus));
        } else {
            factor = Math.pow(0.5, Math.abs(zoomStatus));
        }

        bitmap_bck.scale *= factor;
        bitmap_bck.scaleX *= factor;
        bitmap_bck.scaleY *= factor;
        canvas.height = bitmap_bck.image.height * bitmap_bck.scale;
        canvas.width = bitmap_bck.image.width * bitmap_bck.scale;

        for (var i = 0; i < nMarkers; i++) {
            markersVec[i].x *= factor;
            markersVec[i].y *= factor;
        }
        featurePanel.x *= factor;
        featurePanel.y *= factor;
        update = true;
        //setUpdate(true)
        zoomStatus = 0;
    }

    // Function: Print several values to the JavaScript console (TODO: Erase this uppon review)
    function logmarkers() {
        console.log(markersVec[sternalNotchIdx].x, markersVec[sternalNotchIdx].y);
        console.log(markersVec[leftNippleIdx].x, markersVec[leftNippleIdx].y);
        console.log(markersVec[rightContourIdxStart].x, markersVec[rightContourIdxStart].y);
        console.log(markersVec[rightContourIdxEnd].x, markersVec[rightContourIdxEnd].y);
        console.log(markersVec[leftContourIdxEnd].x, markersVec[leftContourIdxEnd].y);
        console.log(featurePanel.x, featurePanel.y);
        console.log(zoomStatus);
    }

    function stop() {
        createjs.Ticker.removeEventListener("tick", tick);
    }

    // Function: Show the classification value
    function showAestheticClassification(props) {
        const classification_value = props.classification_value;
        if (classification_value === 0) {
            return <li><h5 style={{ textAlign: "justify" }}>Aesthetic Classification: Unknown</h5></li>;
        } else if (classification_value === 1) {
            return <li><h5 style={{ textAlign: "justify" }}>Aesthetic Classification: Excellent</h5></li>;
        } else if (classification_value === 2) {
            return <li><h5 style={{ textAlign: "justify" }}>Aesthetic Classification: Good</h5></li>;
        } else if (classification_value === 3) {
            return <li><h5 style={{ textAlign: "justify" }}>Aesthetic Classification: Fair</h5></li>;
        } else if (classification_value === 4) {
            return <li><h5 style={{ textAlign: "justify" }}>Aesthetic Classification: Poor</h5></li>;
        }
    }

    // TODO: Function: Refine the breast contour keypoints using the Dijkstra Shortest-Path Algorithm
    async function refineKeypoints() {
        setIsActive(true)
        var factor = null
        if (zoomStatus < 1.0) {
            factor = Math.pow(2.0, Math.abs(zoomStatus));
        } else {
            factor = Math.pow(0.5, Math.abs(zoomStatus));
        }

        let keypoints = {}; // objCopy will store a copy of the mainObj
        let key;

        for (key in markersVec) {
            keypoints[key] = markersVec[key]; // copies each property to the objCopy object
        }

        let data = new FormData();
        data.append("action", "refine");
        data.append("image", filesList[index]);
        data.append("left_endpoint_x", markersVec[leftContourIdxEnd].x)
        data.append("left_endpoint_y", markersVec[leftContourIdxEnd].y)
        data.append("left_midpoint_x", markersVec[leftContourIdxStart].x)
        data.append("left_midpoint_y", markersVec[leftContourIdxStart].y)
        data.append("right_endpoint_x", markersVec[rightContourIdxStart].x)
        data.append("right_endpoint_y", markersVec[rightContourIdxStart].y)
        data.append("right_midpoint_x", markersVec[rightContourIdxEnd].x)
        data.append("right_midpoint_y", markersVec[rightContourIdxEnd].y)

        axios.post('https://breloai-backend.inesctec.pt/api/webbcctcore', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
            .then(res => {
                markersVec[rightContourIdxStart].x = res.data.right_contour[16][1]
                markersVec[rightContourIdxStart].y = res.data.right_contour[16][0]

                markersVec[rightContourIdxStart + 1].x = res.data.right_contour[15][1]
                markersVec[rightContourIdxStart + 1].y = res.data.right_contour[15][0]

                markersVec[rightContourIdxStart + 2].x = res.data.right_contour[14][1]
                markersVec[rightContourIdxStart + 2].y = res.data.right_contour[14][0]

                markersVec[rightContourIdxStart + 3].x = res.data.right_contour[13][1]
                markersVec[rightContourIdxStart + 3].y = res.data.right_contour[13][0]

                markersVec[rightContourIdxStart + 4].x = res.data.right_contour[12][1]
                markersVec[rightContourIdxStart + 4].y = res.data.right_contour[12][0]

                markersVec[rightContourIdxStart + 5].x = res.data.right_contour[11][1]
                markersVec[rightContourIdxStart + 5].y = res.data.right_contour[11][0]

                markersVec[rightContourIdxStart + 6].x = res.data.right_contour[10][1]
                markersVec[rightContourIdxStart + 6].y = res.data.right_contour[10][0]

                markersVec[rightContourIdxStart + 7].x = res.data.right_contour[9][1]
                markersVec[rightContourIdxStart + 7].y = res.data.right_contour[9][0]

                markersVec[rightContourIdxStart + 8].x = res.data.right_contour[8][1]
                markersVec[rightContourIdxStart + 8].y = res.data.right_contour[8][0]

                markersVec[rightContourIdxStart + 9].x = res.data.right_contour[7][1]
                markersVec[rightContourIdxStart + 9].y = res.data.right_contour[7][0]

                markersVec[rightContourIdxStart + 10].x = res.data.right_contour[6][1]
                markersVec[rightContourIdxStart + 10].y = res.data.right_contour[6][0]

                markersVec[rightContourIdxStart + 11].x = res.data.right_contour[5][1]
                markersVec[rightContourIdxStart + 11].y = res.data.right_contour[5][0]

                markersVec[rightContourIdxStart + 12].x = res.data.right_contour[4][1]
                markersVec[rightContourIdxStart + 12].y = res.data.right_contour[4][0]

                markersVec[rightContourIdxStart + 13].x = res.data.right_contour[3][1]
                markersVec[rightContourIdxStart + 13].y = res.data.right_contour[3][0]

                markersVec[rightContourIdxStart + 14].x = res.data.right_contour[2][1]
                markersVec[rightContourIdxStart + 14].y = res.data.right_contour[2][0]

                markersVec[rightContourIdxStart + 15].x = res.data.right_contour[1][1]
                markersVec[rightContourIdxStart + 15].y = res.data.right_contour[1][0]

                markersVec[rightContourIdxEnd].x = res.data.right_contour[0][1]
                markersVec[rightContourIdxEnd].y = res.data.right_contour[0][0]

                markersVec[leftContourIdxStart].x = res.data.left_contour[0][1]
                markersVec[leftContourIdxStart].y = res.data.left_contour[0][0]

                markersVec[leftContourIdxStart + 1].x = res.data.left_contour[1][1]
                markersVec[leftContourIdxStart + 1].y = res.data.left_contour[1][0]

                markersVec[leftContourIdxStart + 2].x = res.data.left_contour[2][1]
                markersVec[leftContourIdxStart + 2].y = res.data.left_contour[2][0]

                markersVec[leftContourIdxStart + 3].x = res.data.left_contour[3][1]
                markersVec[leftContourIdxStart + 3].y = res.data.left_contour[3][0]

                markersVec[leftContourIdxStart + 4].x = res.data.left_contour[4][1]
                markersVec[leftContourIdxStart + 4].y = res.data.left_contour[4][0]

                markersVec[leftContourIdxStart + 5].x = res.data.left_contour[5][1]
                markersVec[leftContourIdxStart + 5].y = res.data.left_contour[5][0]

                markersVec[leftContourIdxStart + 6].x = res.data.left_contour[6][1]
                markersVec[leftContourIdxStart + 6].y = res.data.left_contour[6][0]

                markersVec[leftContourIdxStart + 7].x = res.data.left_contour[7][1]
                markersVec[leftContourIdxStart + 7].y = res.data.left_contour[7][0]

                markersVec[leftContourIdxStart + 8].x = res.data.left_contour[8][1]
                markersVec[leftContourIdxStart + 8].y = res.data.left_contour[8][0]

                markersVec[leftContourIdxStart + 9].x = res.data.left_contour[9][1]
                markersVec[leftContourIdxStart + 9].y = res.data.left_contour[9][0]

                markersVec[leftContourIdxStart + 10].x = res.data.left_contour[10][1]
                markersVec[leftContourIdxStart + 10].y = res.data.left_contour[10][0]

                markersVec[leftContourIdxStart + 11].x = res.data.left_contour[11][1]
                markersVec[leftContourIdxStart + 11].y = res.data.left_contour[11][0]

                markersVec[leftContourIdxStart + 12].x = res.data.left_contour[12][1]
                markersVec[leftContourIdxStart + 12].y = res.data.left_contour[12][0]

                markersVec[leftContourIdxStart + 13].x = res.data.left_contour[13][1]
                markersVec[leftContourIdxStart + 13].y = res.data.left_contour[13][0]

                markersVec[leftContourIdxStart + 14].x = res.data.left_contour[14][1]
                markersVec[leftContourIdxStart + 14].y = res.data.left_contour[14][0]

                markersVec[leftContourIdxStart + 15].x = res.data.left_contour[15][1]
                markersVec[leftContourIdxStart + 15].y = res.data.left_contour[15][0]

                markersVec[leftContourIdxEnd].x = res.data.left_contour[16][1]
                markersVec[leftContourIdxEnd].y = res.data.left_contour[16][0]

                savePoints(index, markersVec)

                update = true;
                //setUpdate(true)
                setIsActive(false)
            })
    }

    let savePoints = (index, markersVec) => {
        pointsList[index][68] = markersVec[sternalNotchIdx].x
        pointsList[index][69] = markersVec[sternalNotchIdx].y

        pointsList[index][72] = markersVec[leftNippleIdx].x
        pointsList[index][73] = markersVec[leftNippleIdx].y

        pointsList[index][70] = markersVec[rightNippleIdx].x
        pointsList[index][71] = markersVec[rightNippleIdx].y

        pointsList[index][34] = markersVec[rightContourIdxStart].x
        pointsList[index][35] = markersVec[rightContourIdxStart].y

        pointsList[index][36] = markersVec[rightContourIdxStart + 1].x
        pointsList[index][37] = markersVec[rightContourIdxStart + 1].y

        pointsList[index][38] = markersVec[rightContourIdxStart + 2].x
        pointsList[index][39] = markersVec[rightContourIdxStart + 2].y

        pointsList[index][40] = markersVec[rightContourIdxStart + 3].x
        pointsList[index][41] = markersVec[rightContourIdxStart + 3].y

        pointsList[index][42] = markersVec[rightContourIdxStart + 4].x
        pointsList[index][43] = markersVec[rightContourIdxStart + 4].y

        pointsList[index][44] = markersVec[rightContourIdxStart + 5].x
        pointsList[index][45] = markersVec[rightContourIdxStart + 5].y

        pointsList[index][46] = markersVec[rightContourIdxStart + 6].x
        pointsList[index][47] = markersVec[rightContourIdxStart + 6].y

        pointsList[index][48] = markersVec[rightContourIdxStart + 7].x
        pointsList[index][49] = markersVec[rightContourIdxStart + 7].y

        pointsList[index][50] = markersVec[rightContourIdxStart + 8].x
        pointsList[index][51] = markersVec[rightContourIdxStart + 8].y

        pointsList[index][52] = markersVec[rightContourIdxStart + 9].x
        pointsList[index][53] = markersVec[rightContourIdxStart + 9].y

        pointsList[index][54] = markersVec[rightContourIdxStart + 10].x
        pointsList[index][55] = markersVec[rightContourIdxStart + 10].y

        pointsList[index][56] = markersVec[rightContourIdxStart + 11].x
        pointsList[index][57] = markersVec[rightContourIdxStart + 11].y

        pointsList[index][58] = markersVec[rightContourIdxStart + 12].x
        pointsList[index][59] = markersVec[rightContourIdxStart + 12].y

        pointsList[index][60] = markersVec[rightContourIdxStart + 13].x
        pointsList[index][61] = markersVec[rightContourIdxStart + 13].y

        pointsList[index][62] = markersVec[rightContourIdxStart + 14].x
        pointsList[index][63] = markersVec[rightContourIdxStart + 14].y

        pointsList[index][64] = markersVec[rightContourIdxStart + 15].x
        pointsList[index][65] = markersVec[rightContourIdxStart + 15].y

        pointsList[index][66] = markersVec[rightContourIdxEnd].x
        pointsList[index][67] = markersVec[rightContourIdxEnd].y

        pointsList[index][0] = markersVec[leftContourIdxStart].x
        pointsList[index][1] = markersVec[leftContourIdxStart].y

        pointsList[index][2] = markersVec[leftContourIdxStart + 1].x
        pointsList[index][3] = markersVec[leftContourIdxStart + 1].y

        pointsList[index][4] = markersVec[leftContourIdxStart + 2].x
        pointsList[index][5] = markersVec[leftContourIdxStart + 2].y

        pointsList[index][6] = markersVec[leftContourIdxStart + 3].x
        pointsList[index][7] = markersVec[leftContourIdxStart + 3].y

        pointsList[index][8] = markersVec[leftContourIdxStart + 4].x
        pointsList[index][9] = markersVec[leftContourIdxStart + 4].y

        pointsList[index][10] = markersVec[leftContourIdxStart + 5].x
        pointsList[index][11] = markersVec[leftContourIdxStart + 5].y

        pointsList[index][12] = markersVec[leftContourIdxStart + 6].x
        pointsList[index][13] = markersVec[leftContourIdxStart + 6].y

        pointsList[index][14] = markersVec[leftContourIdxStart + 7].x
        pointsList[index][15] = markersVec[leftContourIdxStart + 7].y

        pointsList[index][16] = markersVec[leftContourIdxStart + 8].x
        pointsList[index][17] = markersVec[leftContourIdxStart + 8].y

        pointsList[index][18] = markersVec[leftContourIdxStart + 9].x
        pointsList[index][19] = markersVec[leftContourIdxStart + 9].y

        pointsList[index][20] = markersVec[leftContourIdxStart + 10].x
        pointsList[index][21] = markersVec[leftContourIdxStart + 10].y

        pointsList[index][22] = markersVec[leftContourIdxStart + 11].x
        pointsList[index][23] = markersVec[leftContourIdxStart + 11].y

        pointsList[index][24] = markersVec[leftContourIdxStart + 12].x
        pointsList[index][25] = markersVec[leftContourIdxStart + 12].y

        pointsList[index][26] = markersVec[leftContourIdxStart + 13].x
        pointsList[index][27] = markersVec[leftContourIdxStart + 13].y

        pointsList[index][28] = markersVec[leftContourIdxStart + 14].x
        pointsList[index][29] = markersVec[leftContourIdxStart + 14].y

        pointsList[index][30] = markersVec[leftContourIdxStart + 15].x
        pointsList[index][31] = markersVec[leftContourIdxStart + 15].y

        pointsList[index][32] = markersVec[leftContourIdxEnd].x
        pointsList[index][33] = markersVec[leftContourIdxEnd].y
    }

    let loadPoints = (index, list) => {
        pointsList[index] = []
        pointsList[index].push(list['left_midpoint_x'])
        pointsList[index].push(list['left_midpoint_y'])
        pointsList[index].push(list['left_point_15_x'])
        pointsList[index].push(list['left_point_15_y'])
        pointsList[index].push(list['left_point_14_x'])
        pointsList[index].push(list['left_point_14_y'])
        pointsList[index].push(list['left_point_13_x'])
        pointsList[index].push(list['left_point_13_y'])
        pointsList[index].push(list['left_point_12_x'])
        pointsList[index].push(list['left_point_12_y'])
        pointsList[index].push(list['left_point_11_x'])
        pointsList[index].push(list['left_point_11_y'])
        pointsList[index].push(list['left_point_10_x'])
        pointsList[index].push(list['left_point_10_y'])
        pointsList[index].push(list['left_point_9_x'])
        pointsList[index].push(list['left_point_9_y'])
        pointsList[index].push(list['left_point_8_x'])
        pointsList[index].push(list['left_point_8_y'])
        pointsList[index].push(list['left_point_7_x'])
        pointsList[index].push(list['left_point_7_y'])
        pointsList[index].push(list['left_point_6_x'])
        pointsList[index].push(list['left_point_6_y'])
        pointsList[index].push(list['left_point_5_x'])
        pointsList[index].push(list['left_point_5_y'])
        pointsList[index].push(list['left_point_4_x'])
        pointsList[index].push(list['left_point_4_y'])
        pointsList[index].push(list['left_point_3_x'])
        pointsList[index].push(list['left_point_3_y'])
        pointsList[index].push(list['left_point_2_x'])
        pointsList[index].push(list['left_point_2_y'])
        pointsList[index].push(list['left_point_1_x'])
        pointsList[index].push(list['left_point_1_y'])
        pointsList[index].push(list['left_endpoint_x'])
        pointsList[index].push(list['left_endpoint_y'])

        pointsList[index].push(list['right_endpoint_x'])
        pointsList[index].push(list['right_endpoint_y'])
        pointsList[index].push(list['right_point_1_x'])
        pointsList[index].push(list['right_point_1_y'])
        pointsList[index].push(list['right_point_2_x'])
        pointsList[index].push(list['right_point_2_y'])
        pointsList[index].push(list['right_point_3_x'])
        pointsList[index].push(list['right_point_3_y'])
        pointsList[index].push(list['right_point_4_x'])
        pointsList[index].push(list['right_point_4_y'])
        pointsList[index].push(list['right_point_5_x'])
        pointsList[index].push(list['right_point_5_y'])
        pointsList[index].push(list['right_point_6_x'])
        pointsList[index].push(list['right_point_6_y'])
        pointsList[index].push(list['right_point_7_x'])
        pointsList[index].push(list['right_point_7_y'])
        pointsList[index].push(list['right_point_8_x'])
        pointsList[index].push(list['right_point_8_y'])
        pointsList[index].push(list['right_point_9_x'])
        pointsList[index].push(list['right_point_9_y'])
        pointsList[index].push(list['right_point_10_x'])
        pointsList[index].push(list['right_point_10_y'])
        pointsList[index].push(list['right_point_11_x'])
        pointsList[index].push(list['right_point_11_y'])
        pointsList[index].push(list['right_point_12_x'])
        pointsList[index].push(list['right_point_12_y'])
        pointsList[index].push(list['right_point_13_x'])
        pointsList[index].push(list['right_point_13_y'])
        pointsList[index].push(list['right_point_14_x'])
        pointsList[index].push(list['right_point_14_y'])
        pointsList[index].push(list['right_point_15_x'])
        pointsList[index].push(list['right_point_15_y'])
        pointsList[index].push(list['right_midpoint_x'])
        pointsList[index].push(list['right_midpoint_y'])

        pointsList[index].push(list['sternal_notch_x'])
        pointsList[index].push(list['sternal_notch_y'])
        pointsList[index].push(list['right_nipple_x'])
        pointsList[index].push(list['right_nipple_y'])
        pointsList[index].push(list['left_nipple_x'])
        pointsList[index].push(list['left_nipple_y'])
    }

    let saveClassification = (index, data) => {
        /*classificationInfoList[index] = {
            'bra_value': parseInt(data['bra_value']),
            'lbc_value': parseInt(data['lbc_value']),
            'unr_value': parseInt(data['unr_value']),
            'bce_value': parseInt(data['bce_value']),
            'bcd_value': parseInt(data['bcd_value']),
            'bad_value': parseInt(data['bad_value']),
            'bod_value': parseInt(data['bod_value']),
            'p_bra_value': parseInt(data['p_bra_value']),
            'p_lbc_value': parseInt(data['p_lbc_value']),
            'p_unr_value': parseInt(data['p_unr_value']),
            'p_bce_value': parseInt(data['p_bce_value']),
            'p_bcd_value': parseInt(data['p_bcd_value']),
            'p_bad_value': parseInt(data['p_bad_value']),
            'p_bod_value': parseInt(data['p_bod_value']),
            'cx2l_value': parseInt(data['cx2l_value']),
            'cx2a_value': parseInt(data['cx2a_value']),
            'cx2b_value': parseInt(data['cx2b_value']),
            'cx2lab_value': parseInt(data['cx2lab_value']),
            'cemdl_value': parseInt(data['cemdl_value']),
            'cemda_value': parseInt(data['cemda_value']),
            'cemdb_value': parseInt(data['cemdb_value']),
            'cemdlab_value': parseInt(data['cemdlab_value']),
            'aesthetic_classification': parseInt(data['aesthetic_classification'])
        }*/
        classificationInfoList[index] = data
    }

    // Function: Compute the features and get the aesthetic classification of a given image 
    let getClassification = async (image) => {
        let data = new FormData();
        data.append("action", "classify");
        data.append("image", filesList[index]);
        data.append("left_endpoint", [markersVec[leftContourIdxEnd].x, markersVec[leftContourIdxEnd].y])
        data.append("left_point_1", [markersVec[leftContourIdxStart + 15].x, markersVec[leftContourIdxStart + 15].y])
        data.append("left_point_2", [markersVec[leftContourIdxStart + 14].x, markersVec[leftContourIdxStart + 14].y])
        data.append("left_point_3", [markersVec[leftContourIdxStart + 13].x, markersVec[leftContourIdxStart + 13].y])
        data.append("left_point_4", [markersVec[leftContourIdxStart + 12].x, markersVec[leftContourIdxStart + 12].y])
        data.append("left_point_5", [markersVec[leftContourIdxStart + 11].x, markersVec[leftContourIdxStart + 11].y])
        data.append("left_point_6", [markersVec[leftContourIdxStart + 10].x, markersVec[leftContourIdxStart + 10].y])
        data.append("left_point_7", [markersVec[leftContourIdxStart + 9].x, markersVec[leftContourIdxStart + 9].y])
        data.append("left_point_8", [markersVec[leftContourIdxStart + 8].x, markersVec[leftContourIdxStart + 8].y])
        data.append("left_point_9", [markersVec[leftContourIdxStart + 7].x, markersVec[leftContourIdxStart + 7].y])
        data.append("left_point_10", [markersVec[leftContourIdxStart + 6].x, markersVec[leftContourIdxStart + 6].y])
        data.append("left_point_11", [markersVec[leftContourIdxStart + 5].x, markersVec[leftContourIdxStart + 5].y])
        data.append("left_point_12", [markersVec[leftContourIdxStart + 4].x, markersVec[leftContourIdxStart + 4].y])
        data.append("left_point_13", [markersVec[leftContourIdxStart + 3].x, markersVec[leftContourIdxStart + 3].y])
        data.append("left_point_14", [markersVec[leftContourIdxStart + 2].x, markersVec[leftContourIdxStart + 2].y])
        data.append("left_point_15", [markersVec[leftContourIdxStart + 1].x, markersVec[leftContourIdxStart + 1].y])
        data.append("left_midpoint", [markersVec[leftContourIdxStart].x, markersVec[leftContourIdxStart].y])

        data.append("right_endpoint", [markersVec[rightContourIdxStart].x, markersVec[rightContourIdxStart].y])
        data.append("right_point_1", [markersVec[rightContourIdxStart + 1].x, markersVec[rightContourIdxStart + 1].y])
        data.append("right_point_2", [markersVec[rightContourIdxStart + 2].x, markersVec[rightContourIdxStart + 2].y])
        data.append("right_point_3", [markersVec[rightContourIdxStart + 3].x, markersVec[rightContourIdxStart + 3].y])
        data.append("right_point_4", [markersVec[rightContourIdxStart + 4].x, markersVec[rightContourIdxStart + 4].y])
        data.append("right_point_5", [markersVec[rightContourIdxStart + 5].x, markersVec[rightContourIdxStart + 5].y])
        data.append("right_point_6", [markersVec[rightContourIdxStart + 6].x, markersVec[rightContourIdxStart + 6].y])
        data.append("right_point_7", [markersVec[rightContourIdxStart + 7].x, markersVec[rightContourIdxStart + 7].y])
        data.append("right_point_8", [markersVec[rightContourIdxStart + 8].x, markersVec[rightContourIdxStart + 8].y])
        data.append("right_point_9", [markersVec[rightContourIdxStart + 9].x, markersVec[rightContourIdxStart + 9].y])
        data.append("right_point_10", [markersVec[rightContourIdxStart + 10].x, markersVec[rightContourIdxStart + 10].y])
        data.append("right_point_11", [markersVec[rightContourIdxStart + 11].x, markersVec[rightContourIdxStart + 11].y])
        data.append("right_point_12", [markersVec[rightContourIdxStart + 12].x, markersVec[rightContourIdxStart + 12].y])
        data.append("right_point_13", [markersVec[rightContourIdxStart + 13].x, markersVec[rightContourIdxStart + 13].y])
        data.append("right_point_14", [markersVec[rightContourIdxStart + 14].x, markersVec[rightContourIdxStart + 14].y])
        data.append("right_point_15", [markersVec[rightContourIdxStart + 15].x, markersVec[rightContourIdxStart + 15].y])
        data.append("right_midpoint", [markersVec[rightContourIdxEnd].x, markersVec[rightContourIdxEnd].y])

        data.append("left_nipple", [markersVec[leftNippleIdx].x, markersVec[leftNippleIdx].y])
        data.append("right_nipple", [markersVec[rightNippleIdx].x, markersVec[rightNippleIdx].y])
        data.append("sternal_notch", [markersVec[sternalNotchIdx].x, markersVec[sternalNotchIdx].y])
        data.append("scale_mark", [markersVec[markScaleIdx].x, markersVec[markScaleIdx].y])

        axios.post('https://breloai-backend.inesctec.pt/api/webbcctcore', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
            .then(res => {
                set_bra_value(res.data['bra_value'])
                set_lbc_value(res.data['lbc_value'])
                set_unr_value(res.data['unr_value'])
                set_bce_value(res.data['bce_value'])
                set_bcd_value(res.data['bcd_value'])
                set_bad_value(res.data['bad_value'])
                set_bod_value(res.data['bod_value'])
                set_p_bra_value(res.data['p_bra_value'])
                set_p_lbc_value(res.data['p_lbc_value'])
                set_p_unr_value(res.data['p_unr_value'])
                set_p_bce_value(res.data['p_bce_value'])
                set_p_bcd_value(res.data['p_bcd_value'])
                set_p_bad_value(res.data['p_bad_value'])
                set_p_bod_value(res.data['p_bod_value'])
                set_cx2l_value(res.data['cx2l_value'])
                set_cx2a_value(res.data['cx2a_value'])
                set_cx2b_value(res.data['cx2b_value'])
                set_cx2lab_value(res.data['cx2lab_value'])
                set_cemdl_value(res.data['cemdl_value'])
                set_cemda_value(res.data['cemda_value'])
                set_cemdb_value(res.data['cemdb_value'])
                set_cemdlab_value(res.data['cemdlab_value'])
                set_classification_value(res.data['aesthetic_classification'])
                //set_scale_value(res.data['scale_value'])
                saveClassification(index, res.data)
                setShowFeedback(false)
            })
            .catch(err => {
                setShowFeedback(true)
            })
    }

    const [firstRequestSent, setFirstRequestSent] = useState(false)

    let getInitialKeypoints = async (index, newCanvas) => {
        if (!firstRequestSent) {
            if (oldShaList.indexOf(shaList[index]) == -1) {
                if (!(index in pointsList)) {
                    setIsActive(true)
                    let data = new FormData();
                    data.append("action", "plot");
                    data.append("image", filesList[index]);
                    axios.post('https://breloai-backend.inesctec.pt/api/webbcctcore', data, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    })
                        .then(res => {
                            setIsActive(false)
                            pointsList[index] = res.data['keypoints']
                            init(newCanvas, index, res.data['keypoints'])
                        })
                } else {
                    init(newCanvas, index, pointsList[index])
                }
            }
            else {
                let new_index = oldShaList.indexOf(shaList[index])
                setFirstRequestSent(true)
                init(newCanvas, index, pointsList[new_index])
            }
        }
    }

    let showClassificationInfo = (index) => {
        if (index in classificationInfoList) {
            set_bra_value(classificationInfoList[index]['bra_value'])
            set_lbc_value(classificationInfoList[index]['lbc_value'])
            set_unr_value(classificationInfoList[index]['unr_value'])
            set_bce_value(classificationInfoList[index]['bce_value'])
            set_bcd_value(classificationInfoList[index]['bcd_value'])
            set_bad_value(classificationInfoList[index]['bad_value'])
            set_bod_value(classificationInfoList[index]['bod_value'])
            set_p_bra_value(classificationInfoList[index]['p_bra_value'])
            set_p_lbc_value(classificationInfoList[index]['p_lbc_value'])
            set_p_unr_value(classificationInfoList[index]['p_unr_value'])
            set_p_bce_value(classificationInfoList[index]['p_bce_value'])
            set_p_bcd_value(classificationInfoList[index]['p_bcd_value'])
            set_p_bad_value(classificationInfoList[index]['p_bad_value'])
            set_p_bod_value(classificationInfoList[index]['p_bod_value'])
            set_cx2l_value(classificationInfoList[index]['cx2l_value'])
            set_cx2a_value(classificationInfoList[index]['cx2a_value'])
            set_cx2b_value(classificationInfoList[index]['cx2b_value'])
            set_cx2lab_value(classificationInfoList[index]['cx2lab_value'])
            set_cemdl_value(classificationInfoList[index]['cemdl_value'])
            set_cemda_value(classificationInfoList[index]['cemda_value'])
            set_cemdb_value(classificationInfoList[index]['cemdb_value'])
            set_cemdlab_value(classificationInfoList[index]['cemdlab_value'])
            set_classification_value(classificationInfoList[index]['aesthetic_classification'])
        }
        else {
            set_bra_value(undefined)
            set_lbc_value(undefined)
            set_unr_value(undefined)
            set_bce_value(undefined)
            set_bcd_value(undefined)
            set_bad_value(undefined)
            set_bod_value(undefined)
            set_p_bra_value(undefined)
            set_p_lbc_value(undefined)
            set_p_unr_value(undefined)
            set_p_bce_value(undefined)
            set_p_bcd_value(undefined)
            set_p_bad_value(undefined)
            set_p_bod_value(undefined)
            set_cx2l_value(undefined)
            set_cx2a_value(undefined)
            set_cx2b_value(undefined)
            set_cx2lab_value(undefined)
            set_cemdl_value(undefined)
            set_cemda_value(undefined)
            set_cemdb_value(undefined)
            set_cemdlab_value(undefined)
            set_classification_value(undefined)
        }
    }

    let [drawGallery, setDrawGallery] = useState(false)

    const [pointsList, setPointsList] = useState({})
    const [classificationInfoList, setClassificationInfoList] = useState({})

    let prepareNewCanvas = (index) => {
        setFirstRequestSent(false)
        let canvas = document.getElementById("bcctCanvas")
        canvas.setAttribute("id", "bcctCanvasOld")
        canvas.setAttribute("hidden", "")
        let canvasContainer = canvas.parentElement
        //canvas?.remove()

        const newCanvas = document.createElement("canvas")
        newCanvas.setAttribute("id", "bcctCanvas")
        style(newCanvas, { border: "1px solid #000000", maxWidth: "100%" })
        //const canvasContainer = document.getElementById("canvasContainer")
        canvasContainer.appendChild(newCanvas)

        if (!(index in pointsList)) {
            getInitialKeypoints(index, newCanvas)
        }
        else {
            let new_index;
            if (oldShaList.length != 0) {
                new_index = oldShaList.indexOf(shaList[index])
            }
            else {
                new_index = index
            }
            init(newCanvas, index, pointsList[new_index])
        }
    }

    const [filesList, setFilesList] = useState([]);
    const [imagesList, setImagesList] = useState([]);
    const [images, setImages] = useState([])
    const [index, setIndex] = useState(0)
    const [shaList, setShaList] = useState([])
    const [oldShaList, setOldShaList] = useState([])
    const [loadcsv, setLoadCSV] = useState(false)
    const [csv, setCSV] = useState(null)
    const [base64List, setBase64List] = useState([]);

    const handleChange = async (files) => {
        let aux = []
        let count = 0
        await Array.from(files).forEach(async (file) => {
            if (file.type != "text/csv") {
                filesList.push(file)
                count = count + 1
                await getBase64(file, count)
                images.push({ original: URL.createObjectURL(file), thumbnail: URL.createObjectURL(file) });
                setDrawGallery(true)
            }
            else {
                setCSV(file)
                await loadCsv(file)
            }
        })
        setErrors({})
    };

    async function loadCsv(file) {
        if (file.name == "download.csv") {
            setLoadCSV(false)
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: async function (results) {
                    let keys = Object.keys(results.data)
                    await keys.forEach((key) => {
                        loadPoints(parseInt(key), results.data[key])
                        saveClassification(parseInt(key), results.data[key])
                        oldShaList.push(results.data[key]['sha256'])
                    })
                },
            })
        }
        else if (file.name == "download2.csv") {
            setLoadCSV(false)
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: async function (results) {
                    let keys = Object.keys(results.data)
                    await keys.forEach(async (key) => {
                        let base64String = results.data[key]['base64'] + ',' + results.data[key].__parsed_extra
                        let image = new createjs.Bitmap(base64String)
                        let temp_imageFile = dataURLtoFile(base64String, "test")
                        filesList.push(temp_imageFile)
                        images.push({ original: URL.createObjectURL(temp_imageFile), thumbnail: URL.createObjectURL(temp_imageFile) });
                        imagesList.push(image)
                        await hashFromString(base64String).then((res) => {
                            shaList.push(res)
                            prepareNewCanvas(0)
                            setDrawGallery(true)
                        })
                        base64List.push(base64String)
                    })
                },
            })
        }
    }

    function dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    const [errors, setErrors] = useState({})
    const fileTypes = ["JPG", "PNG", "jpeg", "png", "jpg", "tiff", "tif", "bmp", "csv"];

    let handleDelete = async (file) => {
        let newFiles = filesList.filter((x) => x.name !== file.name)
        setFilesList(newFiles)
    }

    let galleryRef = useRef(null)
    const [activeImage, setActiveImage] = useState("")

    let getSlide = async () => {
        let aux = galleryRef.current.getCurrentIndex()
        setIndex(aux)
        prepareNewCanvas(aux)
        showClassificationInfo(aux)
    }

    let getBase64 = (file, count) => {
        let reader = new FileReader()
        reader.onload = async () => {
            let image = new createjs.Bitmap(reader.result)
            imagesList.push(image)
            base64List.push(reader.result)
            hashFromString(reader.result).then(async (res) => {
                shaList.push(res)
                if (count == 1) {
                    getInitialKeypoints(0, null)
                }
            })
        }
        reader.readAsDataURL(file)
    }

    async function hashFromString(string) {
        const hash = await crypto.subtle.digest("SHA-256", (new TextEncoder()).encode(string))
        return "sha256-" + btoa(String.fromCharCode(...new Uint8Array(hash)))
    }

    useEffect(() => {

    }, [filesList])

    const style = (node, styles) => Object.keys(styles).forEach(key => node.style[key] = styles[key])

    const csvmaker = function (pointsList, classificationInfoList, type) {
        let data = {}
        if (type == 1) {

            for (let i = 0; i <= filesList.length; i++) {
                if (i in pointsList) {
                    data[i] = {
                        file: filesList[i]['name'],
                        sha256: shaList[i],

                        right_endpoint_x: pointsList[i][34],
                        right_endpoint_y: pointsList[i][35],

                        right_point_1_x: pointsList[i][36],
                        right_point_1_y: pointsList[i][37],

                        right_point_2_x: pointsList[i][38],
                        right_point_2_y: pointsList[i][39],

                        right_point_3_x: pointsList[i][40],
                        right_point_3_y: pointsList[i][41],

                        right_point_4_x: pointsList[i][42],
                        right_point_4_y: pointsList[i][43],

                        right_point_5_x: pointsList[i][44],
                        right_point_5_y: pointsList[i][45],

                        right_point_6_x: pointsList[i][46],
                        right_point_6_y: pointsList[i][47],

                        right_point_7_x: pointsList[i][48],
                        right_point_7_y: pointsList[i][49],

                        right_point_8_x: pointsList[i][50],
                        right_point_8_y: pointsList[i][51],

                        right_point_9_x: pointsList[i][52],
                        right_point_9_y: pointsList[i][53],

                        right_point_10_x: pointsList[i][54],
                        right_point_10_y: pointsList[i][55],

                        right_point_11_x: pointsList[i][56],
                        right_point_11_y: pointsList[i][57],

                        right_point_12_x: pointsList[i][58],
                        right_point_12_y: pointsList[i][59],

                        right_point_13_x: pointsList[i][60],
                        right_point_13_y: pointsList[i][61],

                        right_point_14_x: pointsList[i][62],
                        right_point_14_y: pointsList[i][63],

                        right_point_15_x: pointsList[i][64],
                        right_point_15_y: pointsList[i][65],

                        right_midpoint_x: pointsList[i][66],
                        right_midpoint_y: pointsList[i][67],


                        left_endpoint_x: pointsList[i][32],
                        left_endpoint_y: pointsList[i][33],

                        left_point_1_x: pointsList[i][30],
                        left_point_1_y: pointsList[i][31],

                        left_point_2_x: pointsList[i][28],
                        left_point_2_y: pointsList[i][29],

                        left_point_3_x: pointsList[i][26],
                        left_point_3_y: pointsList[i][27],

                        left_point_4_x: pointsList[i][24],
                        left_point_4_y: pointsList[i][25],

                        left_point_5_x: pointsList[i][22],
                        left_point_5_y: pointsList[i][23],

                        left_point_6_x: pointsList[i][20],
                        left_point_6_y: pointsList[i][21],

                        left_point_7_x: pointsList[i][18],
                        left_point_7_y: pointsList[i][19],

                        left_point_8_x: pointsList[i][16],
                        left_point_8_y: pointsList[i][17],

                        left_point_9_x: pointsList[i][14],
                        left_point_9_y: pointsList[i][15],

                        left_point_10_x: pointsList[i][12],
                        left_point_10_y: pointsList[i][13],

                        left_point_11_x: pointsList[i][10],
                        left_point_11_y: pointsList[i][11],

                        left_point_12_x: pointsList[i][8],
                        left_point_12_y: pointsList[i][9],

                        left_point_13_x: pointsList[i][6],
                        left_point_13_y: pointsList[i][7],

                        left_point_14_x: pointsList[i][4],
                        left_point_14_y: pointsList[i][5],

                        left_point_15_x: pointsList[i][2],
                        left_point_15_y: pointsList[i][3],

                        left_midpoint_x: pointsList[i][0],
                        left_midpoint_y: pointsList[i][1],


                        left_nipple_x: pointsList[i][72],
                        left_nipple_y: pointsList[i][73],


                        right_nipple_x: pointsList[i][70],
                        right_nipple_y: pointsList[i][71],


                        sternal_notch_x: pointsList[i][68],
                        sternal_notch_y: pointsList[i][69],


                        bra_value: classificationInfoList[i] ? classificationInfoList[i]['bra_value'] : "Unknown",
                        lbc_value: classificationInfoList[i] ? classificationInfoList[i]['lbc_value'] : "Unknown",
                        unr_value: classificationInfoList[i] ? classificationInfoList[i]['unr_value'] : "Unknown",
                        bce_value: classificationInfoList[i] ? classificationInfoList[i]['bce_value'] : "Unknown",
                        bcd_value: classificationInfoList[i] ? classificationInfoList[i]['bcd_value'] : "Unknown",
                        bad_value: classificationInfoList[i] ? classificationInfoList[i]['bad_value'] : "Unknown",
                        bod_value: classificationInfoList[i] ? classificationInfoList[i]['bod_value'] : "Unknown",
                        p_bra_value: classificationInfoList[i] ? classificationInfoList[i]['p_bra_value'] : "Unknown",
                        p_lbc_value: classificationInfoList[i] ? classificationInfoList[i]['p_lbc_value'] : "Unknown",
                        p_unr_value: classificationInfoList[i] ? classificationInfoList[i]['p_unr_value'] : "Unknown",
                        p_bce_value: classificationInfoList[i] ? classificationInfoList[i]['p_bce_value'] : "Unknown",
                        p_bcd_value: classificationInfoList[i] ? classificationInfoList[i]['p_bcd_value'] : "Unknown",
                        p_bad_value: classificationInfoList[i] ? classificationInfoList[i]['p_bad_value'] : "Unknown",
                        p_bod_value: classificationInfoList[i] ? classificationInfoList[i]['p_bod_value'] : "Unknown",
                        cx2l_value: classificationInfoList[i] ? classificationInfoList[i]['cx2l_value'] : "Unknown",
                        cx2a_value: classificationInfoList[i] ? classificationInfoList[i]['cx2a_value'] : "Unknown",
                        cx2b_value: classificationInfoList[i] ? classificationInfoList[i]['cx2b_value'] : "Unknown",
                        cx2lab_value: classificationInfoList[i] ? classificationInfoList[i]['cx2lab_value'] : "Unknown",
                        cemdl_value: classificationInfoList[i] ? classificationInfoList[i]['cemdl_value'] : "Unknown",
                        cemda_value: classificationInfoList[i] ? classificationInfoList[i]['cemda_value'] : "Unknown",
                        cemdb_value: classificationInfoList[i] ? classificationInfoList[i]['cemdb_value'] : "Unknown",
                        cemdlab_value: classificationInfoList[i] ? classificationInfoList[i]['cemdlab_value'] : "Unknown",
                        aesthetic_classification: classificationInfoList[i] ? classificationInfoList[i]['aesthetic_classification'] : "Unknown",
                    }
                }
            }
        }
        else {
            for (let i = 0; i <= filesList.length; i++) {
                if (i in pointsList) {
                    data[i] = {
                        base64: base64List[i]
                    }
                }
            }
        }


        let csvRows = [];
        const headers = Object.keys(data[0]);
        csvRows.push(headers.join(','));
        for (const [key, value] of Object.entries(data)) {
            const values = Object.values(data[key]).join(',');
            csvRows.push(values)
        }

        return csvRows.join('\n')
    }

    let exportData = async () => {
        const csvdata = await csvmaker(pointsList, classificationInfoList, 1);
        const blob = new Blob([csvdata], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('download', 'features.csv');
        a.click()

        const csvdata2 = await csvmaker(pointsList, classificationInfoList, 2);
        const blob2 = new Blob([csvdata2], { type: 'text/csv' });
        const url2 = window.URL.createObjectURL(blob2)
        const a2 = document.createElement('a')
        a2.setAttribute('href', url2)
        a2.setAttribute('download', 'images.csv');
        a2.click()
    }

    return (
        <Container className='webbcct-container mx-2'>

            {/* FIXME: Convert Django conditionals into ReactJS */}
            <div className='text-center mt-3' style={{ width: '100%' }}>
                <h3>BCCT Assessment Features</h3>
            </div>

            <Row>
                <Col className='col-2 webbcct-upload-container'>
                    <FileUploader multiple={true} handleChange={handleChange} name="file" types={fileTypes} />
                    <div className='webbcct-uploaded-images-container mt-3'>
                        {
                            drawGallery ? (
                                <ImageGallery ref={galleryRef} onSlide={() => getSlide()} items={images} showFullscreenButton={false} showPlayButton={false} useWindowKeyDown={false} showIndex={true} useTranslate3D={false}></ImageGallery>
                            )
                                :
                                (<p>No images selected</p>)
                        }
                        <div className='text-center mt-2'>
                            <Button className='FilledButton' id="export" onClick={exportData}>Export Data</Button>
                        </div>
                    </div>
                </Col>

                <Col className='col-9 webbcct-main-container'>
                    <Collapsible className="mt-2" trigger="Show/Hide Features">
                        <Row className='my-4 justify-content-center'>
                            <Col>
                                <Table striped bordered>
                                    <thead>
                                        <tr>
                                            <th colSpan={2}><h4 className="text-center">Dimension Asymmetry Features</h4></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Breast retraction assessment</Tooltip>}>
                                                <td><h5 className="text-center">BRA</h5></td>
                                            </OverlayTrigger>
                                            <td><h5 className="text-center">{bra_value ? bra_value : "None"}</h5></td>
                                        </tr>
                                        <tr>
                                            <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Lower breast contour</Tooltip>}>
                                                <td><h5 className="text-center">LBC</h5></td>
                                            </OverlayTrigger>
                                            <td><h5 className="text-center">{lbc_value ? lbc_value : "None"}</h5></td>
                                        </tr>
                                        <tr>
                                            <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Upward nipple retraction</Tooltip>}>
                                                <td><h5 className="text-center">UNR</h5></td>
                                            </OverlayTrigger>
                                            <td><h5 className="text-center">{unr_value ? unr_value : "None"}</h5></td>
                                        </tr>
                                        <tr>
                                            <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Breast compliance evaluation</Tooltip>}>
                                                <td><h5 className="text-center">BCE</h5></td>
                                            </OverlayTrigger>
                                            <td><h5 className="text-center">{bce_value ? bce_value : "None"}</h5></td>
                                        </tr>
                                        <tr>
                                            <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Breast contour difference</Tooltip>}>
                                                <td><h5 className="text-center">BCD</h5></td>
                                            </OverlayTrigger>
                                            <td><h5 className="text-center">{bcd_value ? bcd_value : "None"}</h5></td>
                                        </tr>
                                        <tr>
                                            <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Breast area difference</Tooltip>}>
                                                <td><h5 className="text-center">BAD</h5></td>
                                            </OverlayTrigger>
                                            <td><h5 className="text-center">{bad_value ? bad_value : "None"}</h5></td>
                                        </tr>
                                        <tr>
                                            <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Breast overlap difference</Tooltip>}>
                                                <td><h5 className="text-center">BOD</h5></td>
                                            </OverlayTrigger>
                                            <td><h5 className="text-center">{bod_value ? bod_value : "None"}</h5></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>

                            <Col>
                                <Table striped bordered>
                                    <thead>
                                        <tr>
                                            <th colSpan={2}><h4 className="text-center">Dimensionless Asymmetry Features</h4></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><h5 className="text-center">pBRA</h5></td>
                                            <td><h5 className="text-center">{p_bra_value ? p_bra_value : "None"}</h5></td>
                                        </tr>
                                        <tr>
                                            <td><h5 className="text-center">pLBC</h5></td>
                                            <td><h5 className="text-center">{p_lbc_value ? p_lbc_value : "None"}</h5></td>
                                        </tr>
                                        <tr>
                                            <td><h5 className="text-center">pUNR</h5></td>
                                            <td><h5 className="text-center">{p_unr_value ? p_unr_value : "None"}</h5></td>
                                        </tr>
                                        <tr>
                                            <td><h5 className="text-center">pBCE</h5></td>
                                            <td><h5 className="text-center">{p_bce_value ? p_bce_value : "None"}</h5></td>
                                        </tr>
                                        <tr>
                                            <td><h5 className="text-center">pBCD</h5></td>
                                            <td><h5 className="text-center">{p_bcd_value ? p_bcd_value : "None"}</h5></td>
                                        </tr>
                                        <tr>
                                            <td><h5 className="text-center">pBAD</h5></td>
                                            <td><h5 className="text-center">{p_bad_value ? p_bad_value : "None"}</h5></td>
                                        </tr>
                                        <tr>
                                            <td><h5 className="text-center">pBOD</h5></td>
                                            <td><h5 className="text-center">{p_bod_value ? p_bod_value : "None"}</h5></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                        <Row className='my-4 justify-content-center'>
                            <Col>
                                <Table striped bordered>
                                    <thead>
                                        <tr>
                                            <th colSpan={2}><h4 className="text-center">Colour Features</h4></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><h5 className="text-center">cX2L</h5></td>
                                            <td><h5 className="text-center">{cx2l_value ? cx2l_value : "None"}</h5></td>
                                        </tr>
                                        <tr>
                                            <td><h5 className="text-center">cX2a</h5></td>
                                            <td><h5 className="text-center">{cx2a_value ? cx2a_value : "None"}</h5></td>
                                        </tr>
                                        <tr>
                                            <td><h5 className="text-center">cX2b</h5></td>
                                            <td><h5 className="text-center">{cx2b_value ? cx2b_value : "None"}</h5></td>
                                        </tr>
                                        <tr>
                                            <td><h5 className="text-center">cX2Lab</h5></td>
                                            <td><h5 className="text-center">{cx2lab_value ? cx2lab_value : "None"}</h5></td>
                                        </tr>
                                        <tr>
                                            <td><h5 className="text-center">cEMDL</h5></td>
                                            <td><h5 className="text-center">{cemdl_value ? cemdl_value : "None"}</h5></td>
                                        </tr>
                                        <tr>
                                            <td><h5 className="text-center">cEMDa</h5></td>
                                            <td><h5 className="text-center">{cemda_value ? cemda_value : "None"}</h5></td>
                                        </tr>
                                        <tr>
                                            <td><h5 className="text-center">cEMDb</h5></td>
                                            <td><h5 className="text-center">{cemdb_value ? cemdb_value : "None"}</h5></td>
                                        </tr>
                                        <tr>
                                            <td><h5 className="text-center">cEMDLab</h5></td>
                                            <td><h5 className="text-center">{cemdlab_value ? cemdlab_value : "None"}</h5></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>

                            <Col>
                                <Table striped bordered>
                                    <thead>
                                        <tr>
                                            <th colSpan={2}><h4 className="text-center">Scar Features</h4></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><h5 className="text-center">sX2L</h5></td>
                                            <td><h5 className="text-center">{sx2l_value ? sx2l_value : "None"}</h5></td>
                                        </tr>
                                        <tr>
                                            <td><h5 className="text-center">sX2a</h5></td>
                                            <td><h5 className="text-center">{sx2a_value ? sx2a_value : "None"}</h5></td>
                                        </tr>
                                        <tr>
                                            <td><h5 className="text-center">sX2b</h5></td>
                                            <td><h5 className="text-center">{sx2b_value ? sx2b_value : "None"}</h5></td>
                                        </tr>
                                        <tr>
                                            <td><h5 className="text-center">sX2Lab</h5></td>
                                            <td><h5 className="text-center">{sx2lab_value ? sx2lab_value : "None"}</h5></td>
                                        </tr>
                                        <tr>
                                            <td><h5 className="text-center">sEMDL</h5></td>
                                            <td><h5 className="text-center">{semdl_value ? semdl_value : "None"}</h5></td>
                                        </tr>
                                        <tr>
                                            <td><h5 className="text-center">sEMDa</h5></td>
                                            <td><h5 className="text-center">{semda_value ? semda_value : "None"}</h5></td>
                                        </tr>
                                        <tr>
                                            <td><h5 className="text-center">sEMDb</h5></td>
                                            <td><h5 className="text-center">{semdb_value ? semdb_value : "None"}</h5></td>
                                        </tr>
                                        <tr>
                                            <td><h5 className="text-center">sEMDLab</h5></td>
                                            <td><h5 className="text-center">{semdlab_value ? semdlab_value : "None"}</h5></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Collapsible>

                    <Row className='my-4 justify-content-center'>
                        <Col>
                            <Table striped bordered>
                                <tbody>

                                    {
                                        classification_value === 0 && showFeedback === false ?
                                            <tr><td><h5 className="text-center">Aesthetic Classification</h5></td> <td><h5 className='text-center'>Unknown</h5></td></tr> :
                                            classification_value === 1 && showFeedback === false ?
                                                <tr><td><h5 className="text-center">Aesthetic Classification</h5></td> <td><h5 className='text-center'>Excellent</h5></td></tr> :
                                                classification_value === 2 && showFeedback === false ?
                                                    <tr><td><h5 className="text-center">Aesthetic Classification</h5></td> <td><h5 className='text-center'>Good</h5></td></tr> :
                                                    classification_value === 3 && showFeedback === false ?
                                                        <tr><td><h5 className="text-center">Aesthetic Classification</h5></td> <td><h5 className='text-center'>Fair</h5></td></tr> :
                                                        classification_value === 4 && showFeedback === false ?
                                                            <tr><td><h5 className="text-center">Aesthetic Classification</h5></td> <td><h5 className='text-center'>Poor</h5></td></tr> :
                                                            showFeedback === true ?
                                                                <tr style={{ backgroundColor: 'red' }}><td><h5 className="text-center">Aesthetic Classification</h5></td> <td><h5 className='text-center'>ERROR</h5></td></tr> :
                                                                <tr><td><h5 className="text-center">Aesthetic Classification</h5></td> <td><h5 className='text-center'>Unknown</h5></td></tr>
                                    }


                                </tbody>
                            </Table>
                        </Col>

                        <Col>
                            <Table striped bordered>
                                <tbody>
                                    <tr>
                                        <td><h5 className="text-center">Scale (cm)</h5></td>
                                        <td><h5 className="text-center">{scale_value}</h5></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>


                    <ul id="assessmentMetrics">


                        {/*<li><h5 style={{ textAlign: "justify" }}>Left breast contour length (cm): {l_breast_contour_len}</h5></li>
                <li><h5 style={{ textAlign: "justify" }}>Right breast contour length (cm): {r_breast_contour_len}</h5></li>
                <li><h5 style={{ textAlign: "justify" }}>Left breast area (cm2): {l_breast_area}</h5></li>
                <li><h5 style={{ textAlign: "justify" }}>Right breast area (cm2): {r_breast_area}</h5></li>
                <li><h5 style={{ textAlign: "justify" }}>Left nipple to inframammary fold distance NI (cm): {l_nipple_ifdni}</h5></li>
                <li><h5 style={{ textAlign: "justify" }}>Right nipple to inframammary fold distance NI (cm): {r_nippple_ifdni}</h5></li>
                <li><h5 style={{ textAlign: "justify" }}>Left nipple horizontal position to sternal notch X1 (cm): {l_nipple_hpsn_x1}</h5></li>
                <li><h5 style={{ textAlign: "justify" }}>Right nipple horizontal position to sternal notch X2 (cm): {r_nipple_hpsn_x2}</h5></li>
                <li><h5 style={{ textAlign: "justify" }}>Left nipple vertical position to sternal notch Y1 (cm): {l_nipple_vpsn_y1}</h5></li>
                <li><h5 style={{ textAlign: "justify" }}>Right nipple vertical position to sternal notch Y2 (cm): {r_nipple_vpsn_y2}</h5></li>
                <li><h5 style={{ textAlign: "justify" }}>sX2L: {sx2l_value}</h5></li>*/}

                    </ul>

                    <h3 className="my-4" style={{ textAlign: "justify" }}>Keypoint Manipulation Functions</h3>
                    <Row className='mb-1'>
                        <Col>
                            <Button className='FilledButton' id="resetZoom">Return to Initial Zoom</Button>
                        </Col>
                        <Col>
                            <Button className='FilledButton' id="zoomIn">Zoom In</Button>
                        </Col>
                        <Col>
                            <Button className='FilledButton' id="zoomOut">Zoom Out</Button>
                        </Col>
                        <Col>
                            <Button className='FilledButton' onClick={refineKeypoints}>Refine Keypoints!</Button>
                        </Col>
                        <Col>
                            <Button className='FilledButton' onClick={() => getClassification()}>Save Features & Classification</Button>
                        </Col>
                    </Row>

                    <div className='mb-4 text-center'>
                        <p className="px-2 mb-0">Countour and Markers Size</p>
                        <input className="slider" id="myRange3" name="sizeSlider" style={{ width: "30%", }} type="range" min="1" max="5" defaultValue='2' />
                    </div>


                    <Container id="canvasContainer">
                        <LoadingOverlay active={isActive} spinner>
                            <canvas id="bcctCanvas" style={{ border: "1px solid #000000", maxWidth: '100%' }}></canvas>
                        </LoadingOverlay>
                    </Container>
                </Col>
            </Row>


        </Container>
    )

}


export default withRouter(WebBcctCorePage)