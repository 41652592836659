import React, { useContext, useState } from 'react'
import Container from 'react-bootstrap/esm/Container'
import AuthContext from '../context/AuthContext'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { withRouter } from "react-router-dom";
import history from '../history';

const RegisterPage = () => {
  const [errors, setErrors] = useState({})

  const initialFormData = Object.freeze({
    username: '', first_name: '', last_name: '', email: '', password: '', password2: '',
  });

  let url = null

  if (window.location.href.includes("localhost")) {
    url = "localhost"
  }
  else {
    url = "194.117.27.135"
  }

  const [formData, updateFormData] = useState(initialFormData);

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
    setErrors({})
  };

  let registerUser = async (e) => {
    e.preventDefault()
    let response = await fetch('https://breloai-backend.inesctec.pt/api/register/', { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'username': e.target.username.value, 'first_name': e.target.first_name.value, 'last_name': e.target.last_name.value, 'email': e.target.email.value, 'password': e.target.password.value, 'password2': e.target.password2.value })
    })
    let data = await response.json()
    if (response.status === 201) {
      history.push("/login")
    }
    else if (response.status === 400 && data.username) {
      setErrors({ username: data.username })
    }
    else if (response.status === 400 && data.password) {
      setErrors({ password: data.password })
    }
    else if (response.status === 400 && data.email) {
      setErrors({ email: data.email })
    }
  }

  return (
    <div className='register-form-container my-auto'>
      <span>Sign Up @ WebBCCT.core</span>
      <Form className='registerForm' onSubmit={registerUser}>
        <Form.Group className="register">
          <Form.Group className='mb-3'>
            <Form.Label>Username</Form.Label>
            <Form.Control name="username" type="text" placeholder="Enter your username" style={{ marginBottom: 0 }} onChange={handleChange} isInvalid={!!errors.username} />
            <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
            <Form.Text className="text-muted" style={{ fontSize: "0.7vw" }}>Letters, digits and @/./+/-/_ only.</Form.Text>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>First Name</Form.Label>
            <Form.Control name="first_name" type="text" placeholder="Enter your first name" onChange={handleChange} />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Last Name</Form.Label>
            <Form.Control name="last_name" type="text" placeholder="Enter your last name" onChange={handleChange} />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Email</Form.Label>
            <Form.Control name="email" type="text" placeholder="Enter your email" onChange={handleChange} isInvalid={!!errors.email} />
            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" name="password" placeholder="Enter your password" style={{ marginBottom: 0 }} onChange={handleChange} isInvalid={!!errors.password} />
            <Form.Text className="text-muted" style={{ marginBottom: 0, fontSize: "0.7vw" }}>Your password must contain at least 8 characters. </Form.Text>
            <Form.Text className="text-muted" style={{ fontSize: "0.7vw" }}>Your password can't be entirely numeric.</Form.Text>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Password confirmation</Form.Label>
            <Form.Control type="password" name="password2" placeholder="Confirm your password" style={{ marginBottom: 0 }} onChange={handleChange} isInvalid={!!errors.password} />
            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
            <Form.Text className="text-muted" style={{ fontSize: "0.7vw" }}>Enter the same password as before, for verification.</Form.Text>
          </Form.Group>
          <Button className="register-Button mb-3" variant="primary" type="submit">Sign Up</Button>
          <p className="text-muted text-center" style={{ fontSize: "0.7vw" }}>Already Have An Account? <a style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { history.push("/login") }}>Log in!</a></p>
        </Form.Group>
      </Form>
    </div>
  )
}

export default withRouter(RegisterPage)
