import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../context/AuthContext';
import { withRouter, generatePath } from "react-router-dom";
import history from '../history';
import { useLocation } from "react-router-dom";

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/esm/Button';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/esm/Container';
import Pagination from 'react-bootstrap/Pagination';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Badge from 'react-bootstrap/Badge';


const PatientsPage = () => {
    let { isAuthenticated, authTokens, user, url } = useContext(AuthContext)

    const location = useLocation()

    const [appState, setAppState] = useState({
        isLoading: true,
        patients: null,
        numPages: null,
        nextPage: null,
        prevPage: null,
    })

    const [query, setQuery] = useState(location.state !== undefined ? location.state.page_info.search : "")
    const [queryValue, setQueryValue] = useState(location.state ? location.state.page_info.filter : "")
    const [orderValue, setOrderValue] = useState(location.state ? location.state.page_info.sort : "3")
    const [surgeryTypeQuery, setSurgeryTypeQuery] = useState(location.state ? location.state.page_info.surgery : "0")
    const [braCupQuery, setBraCupQuery] = useState(location.state ? location.state.page_info.bra_cup : "")
    const [braSizeQuery, setBraSizeQuery] = useState(location.state ? location.state.page_info.bra_size : "")
    const [weightQuery, setWeightQuery] = useState(location.state ? location.state.page_info.weight : "")
    const [heightQuery, setHeightQuery] = useState(location.state ? location.state.page_info.height : "")
    const [active, setActive] = useState(location.state ? location.state.page_info.page : 1)
    const [classificationFilter, setClassificationFilter] = useState(location.state ? location.state.page_info.sort : "0")

    let page_info = {
        source: "home",
        search: query,
        filter: queryValue,
        sort: orderValue,
        page: active,
        surgery: surgeryTypeQuery,
        bra_size: braSizeQuery,
        bra_cup: braCupQuery,
        weight: weightQuery,
        height: heightQuery,
        classification: classificationFilter,
    }

    let getPatients = async (query, queryValue, orderValue, surgeryTypeValue) => {
        let response = await fetch('https://breloai-backend.inesctec.pt/api/patients/?institutional_number=' + query + '&page=' + active + '&patient_status=' + queryValue + '&order=' + orderValue + 
        '&surgery_type=' + surgeryTypeValue + '&bra_size=' + braSizeQuery + '&bra_cup=' + braCupQuery + '&weight=' + weightQuery + '&height=' + heightQuery, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            }
        })
        let data = await response.json()
        if (response.status === 200) {
            setAppState({
                isLoading: false,
                patients: data['results'],
                numPages: Math.ceil(data['count'] / 10),
                nextPage: data['next'] === null ? null : data['next'].replace('http', 'https'),
                prevPage: data['previous'] === null ? null : data['previous'].replace('http', 'https'),
            })
        }
    }

    let getNextPage = async (url) => {
        let response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            }
        })
        let data = await response.json()
        if (response.status === 200) {
            setAppState({
                isLoading: false,
                patients: data['results'],
                numPages: Math.ceil(data['count'] / 10),
                nextPage: data['next'] === null ? null : data['next'].replace('http', 'https'),
                prevPage: data['previous'] === null ? null : data['previous'].replace('http', 'https'),
            })
        }
    }

    let typingTimer
    let doneTypingInterval = 5000
    let searchInstitutionalNumber = (query) => {
        setQuery(query)
        typingTimer = setTimeout(() => {
            setActive(1)
            getPatients(query, queryValue, orderValue, surgeryTypeQuery, braCupQuery, braSizeQuery, weightQuery, heightQuery)
        }, doneTypingInterval)
    }

    let searchStatus = (value) => {
        setQueryValue(value)
        setActive(1)
        getPatients(query, value, orderValue, surgeryTypeQuery, braCupQuery, braSizeQuery, weightQuery, heightQuery)
    }

    let searchSurgeryType = (value) => {
        setSurgeryTypeQuery(value)
        setActive(1)
        getPatients(query, queryValue, orderValue, value, braCupQuery, braSizeQuery, weightQuery, heightQuery)
    }

    let changeOrder = (value) => {
        setOrderValue(value)
        setActive(1)
        getPatients(query, queryValue, value, surgeryTypeQuery, braCupQuery, braSizeQuery, weightQuery, heightQuery)
    }

    useEffect(() => {
        const delaySearch = setTimeout(() => {
            getPatients(query, queryValue, orderValue, surgeryTypeQuery, braCupQuery, braSizeQuery, weightQuery, heightQuery)
        }, 500)
        return () => clearTimeout(delaySearch)
    }, [query, queryValue, orderValue, surgeryTypeQuery, braCupQuery, braSizeQuery, weightQuery, heightQuery])

    let getPatientStatusText = (patient) => {
        if (patient.patient_status === null) {
            return (
                <span> Error</span>
            )
        }
        else if (patient.patient_status === 1) {
            return (
                <h2>
                    <Badge bg="light" style={{ color: 'red' }}>Basic information is missing.</Badge>
                </h2>
            )
        }
        /*else if (patient.patient_status === 2) {
            return (
                <h2>
                    <Badge bg="light" style={{ color: 'red' }}>Basic information is missing.</Badge>
                    <br />
                    <Badge bg="light" style={{ color: 'orange' }}>Patient does not have pre surgery photo moment completeplete</Badge>
                </h2>
            )
        }
        else if (patient.patient_status === 3) {
            return (
                <h2>
                    <Badge bg="light" style={{ color: 'red' }}>Basic information is missing.</Badge>
                    <br />
                    <Badge bg="light" style={{ color: 'blue' }}>Patient does not have annotated photos.</Badge >
                </h2>
            )
        }
        else if (patient.patient_status === 4) {
            return (
                <h2>
                    <Badge bg="light" style={{ color: 'red' }}>Basic information is missing.</Badge>
                    <br />
                    <Badge bg="light" style={{ color: 'orange' }}>Patient does not have pre surgery photo moment complete</Badge>
                    <br />
                    <Badge bg="light" style={{ color: 'blue' }}>Patient does not have annotated photos.</Badge>
                </h2>
            )
        }*/
        else if (patient.patient_status === 5) {
            return (
                <h2>
                    <Badge bg="light" style={{ color: 'orange' }}>Patient does not have pre surgery photo moment complete</Badge>
                </h2>
            )
        }
        /*else if (patient.patient_status === 6) {
            return (
                <h2>
                    <Badge bg="light" style={{ color: 'orange' }}>Patient does not have pre surgery photo moment complete</Badge>
                    <br />
                    <Badge bg="light" style={{ color: 'blue' }}>Patient does not have annotated photos.</Badge>
                </h2>
            )
        }
        */
        else if (patient.patient_status === 7) {
            return (
                <h2>
                    <Badge bg="light" style={{ color: 'blue' }}>Patient does not have annotated photos</Badge>
                </h2>
            )
        }
        else if (patient.patient_status === 8) {
            return (
                <h2>
                    <Badge bg="light" style={{ color: 'green' }}>Patient has all the required information, but isn't CINDERELLA.</Badge>
                </h2>
            )
        }
        else if (patient.patient_status === 9){
            return (
                <h2>
                    <Badge bg="light" style={{ color: 'purple' }}>Patient has all the required information for CINDERELLA.</Badge>
                </h2>
            )
        }
        else if (patient.patient_status === 10){
            return (
                <h2>
                    <Badge bg="light" style={{ color: 'magenta' }}>Patient does not have a complete surgery</Badge>
                </h2>
            )
        }
        /*else if (patient.patient_status === 11){
            return (
                <h2>
                    <Badge bg="light" style={{ color: 'red' }}>Basic information is missing.</Badge>
                    <br />
                    <Badge bg="light" style={{ color: 'magenta' }}>Patient does not have a complete surgery</Badge>
                </h2>
            )
        }*/
        else if (patient.patient_status === 12){
            return (
                <h2>
                    <Badge bg="light" style={{ color: '#F091AD' }}>Patient does not have post surgery photo moment complete or classification or post 1 year photos</Badge>
                </h2>
            )
        }
    }

    let items = [];
    let items_aux = []
    let limit = appState.numPages > 7 ? 7 : appState.numPages
    if (active - 1 == 0 || active - 2 == 0 || active - 3 == 0) {
        for (let i = 1; i <= limit; i++) {
            items_aux.push(i)
        }
    }
    else if (active == appState.numPages || active + 1 == appState.numPages || active + 2 == appState.numPages) {
        for (let i = limit - 1; i >= 0; i--) {
            items_aux.push(appState.numPages - i)
        }
    }
    else {
        for (let i = 1; i <= limit; i++) {
            items_aux.push(active - 4 + i)
        }
    }


    for (let number = 0; number < items_aux.length; number++) {
        let url = appState.nextPage !== null ? appState.nextPage : appState.prevPage !== null ? appState.prevPage : ""
        if (url !== "") {
            url = url.replace(/page=\d+/, "page=" + items_aux[number])
            items.push(
                <Pagination.Item key={items_aux[number]} active={items_aux[number] === active} onClick={() => { setActive(items_aux[number]); getNextPage(url); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }}>
                    {items_aux[number]}
                </Pagination.Item>,
            );
        }
    }

    if (!isAuthenticated) {
        history.push("/")
    }
    else {
        if (appState.isLoading === true) {
            return (
                <Container className='text-center'>
                    <div style={{ marginTop: '22.5%', }}>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                </Container>
            )
        }
        else {
            return (
                <Container>
                    <Breadcrumb>
                        <Breadcrumb.Item active>Patients</Breadcrumb.Item>
                    </Breadcrumb>
                    <Container>
                        <div className='addPatient-div'>
                            <Button variant="outline-primary" className="addPatient-button" onClick={() => history.push(generatePath("/:username/addPatient", { username: user.username }))}><i className="fa fa-plus-circle" aria-hidden="true"></i> Add Patient</Button>
                        </div>
                    </Container>
                    <Container>
                        <Row className="searchContainer">
                            <Col className={'text-center'}>
                                <input type="text" placeholder={"Institutional Number"} style={{ width: '80%' }} onChange={event => setQuery(event.target.value)} value={query} />
                            </Col>
                            <Col className={'text-center'} >
                                <Form.Select onChange={event => searchStatus(event.target.value)} value={queryValue}>
                                    <option value="">--------</option>
                                    <option value="1">Red</option>
                                    <option value="2">Red + Orange</option>
                                    <option value="3">Red + Blue</option>
                                    <option value="4">Red + Orange + Blue</option>
                                    <option value="5">Orange</option>
                                    <option value="6">Orange + Blue</option>
                                    <option value="7">Blue</option>
                                    <option value="8">Green</option>
                                    <option value="9">Purple</option>
                                    <option value="10">Magenta</option>
                                    <option value="11">Red + Magenta</option>
                                    <option value="12">Pink</option>
                                </Form.Select>
                            </Col>
                            <Col className={'text-center'}>
                                <Form.Select onChange={event => changeOrder(event.target.value)}>
                                    <option value="3">Institutinal Number Ascending</option>
                                    <option value="4">Institutinal Number Descending</option>
                                    <option value="1">Date Descending</option>
                                    <option value="2">Date Ascending</option>
                                </Form.Select>
                            </Col>
                            <Col className={'text-center'} >
                                <Form.Select onChange={event => searchSurgeryType(event.target.value)} value={surgeryTypeQuery}>
                                    <option value="0">--------</option>
                                    <option value="1">C1 - Conservative Surgery</option>
                                    <option value="2">C2 - Conservative Surgery with bilateral reduction</option>
                                    <option value="3">C3 - Conservative Surgery with LD or LICAC/TDAP flaps</option>
                                    <option value="4">C4 - Conservative Surgery with bilateral implants</option>
                                    <option value="5">M1 - Mastectomy with unilateral reconstruction with implant</option>
                                    <option value="6">M2 - Mastectomy with unilateral reconstruction with autologous flap</option>
                                    <option value="7">M3 - Mastectomy with bilateral reconstruction with implants</option>
                                    <option value="8">M4 - Mastectomy with bilateral reconstruction with autologous flaps</option>
                                    <option value="9">M5 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with implant (augmentation)</option>
                                    <option value="10">M6 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with reduction</option>
                                    <option value="11">M7 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with reduction</option>
                                    <option value="12">M8 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with implant (augmentation)</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="searchContainer mt-2">
                            <Col className={'text-center'}>
                                <input type="text" placeholder={"Bra Cup"} style={{ width: '80%' }} onChange={event => setBraCupQuery(event.target.value)} value={braCupQuery} />
                            </Col>
                            <Col className={'text-center'}>
                                <input type="number" placeholder={"Bra Size"} style={{ width: '80%' }} onChange={event => setBraSizeQuery(event.target.value)} value={braSizeQuery} />
                            </Col>
                            <Col className={'text-center'}>
                                <input type="number" placeholder={"Weight"} style={{ width: '80%' }} onChange={event => setWeightQuery(event.target.value)} value={weightQuery} />
                            </Col>
                            <Col className={'text-center'}>
                                <input type="number" placeholder={"Height"} style={{ width: '80%' }} onChange={event => setHeightQuery(event.target.value)} value={heightQuery} />
                            </Col>
                        </Row>

                        <div className="row col-12 my-4">
                            <div className="col-8 mx-auto text-center">
                                <h2 >
                                    List of Patients
                                </h2>
                            </div>
                        </div>
                        <div className='align-items-center'>
                            {appState.patients.length !== 0 ?
                                appState.patients.map(patient => (
                                    <Card key={patient.id} className='mb-5 mx-auto'>
                                        <Card.Header className="text-center card-header-text" as="h3" onClick={() => history.push({ pathname: generatePath("/patient/:id", { id: patient.id }), state: { id: patient.id, page: page_info } })}>Patient {patient.institutional_number}</Card.Header>
                                        <Card.Body>
                                            <Card.Text as='div'>
                                                {getPatientStatusText(patient)}
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Footer className="card-footer"><span className='card-footer-text'>Uploaded by: </span>{patient.owner_username}&emsp;&emsp;<span className='card-footer-text'>Uploaded in: </span>{patient.date_posted}</Card.Footer>
                                    </Card>))
                                :
                                <span>No patients found</span>
                            }
                        </div>

                        <Container >
                            <Pagination>
                                <Pagination.First hidden={active === 1 ? true : false} onClick={() => { setActive(1); getNextPage("https://breloai-backend.inesctec.pt/api/patients/?institutional_number=" + query + '&page=' + 1 + '&patient_status=' + queryValue + '&order=' + orderValue + 
        '&surgery_type=' + surgeryTypeQuery + '&bra_size=' + braSizeQuery + '&bra_cup=' + braCupQuery + '&weight=' + weightQuery + '&height=' + heightQuery); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }} />
                                <Pagination.Prev hidden={active === 1 ? true : false} onClick={() => { active - 1 < 1 ? setActive(1) : setActive(active - 1); getNextPage(appState.prevPage); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }} />
                                {items}
                                <Pagination.Next hidden={active === appState.numPages ? true : false} onClick={() => { active + 1 > appState.numPages ? setActive(appState.numPages) : setActive(active + 1); getNextPage(appState.nextPage); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }} />
                                <Pagination.Last hidden={active === appState.numPages ? true : false} onClick={() => { setActive(appState.numPages); getNextPage("https://breloai-backend.inesctec.pt/api/patients/?institutional_number=" + query + '&page=' + appState.numPages + '&patient_status=' + queryValue + '&order=' + orderValue + 
        '&surgery_type=' + surgeryTypeQuery + '&bra_size=' + braSizeQuery + '&bra_cup=' + braCupQuery + '&weight=' + weightQuery + '&height=' + heightQuery); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }} />
                            </Pagination>
                        </Container>

                    </Container>
                </Container>

            )
        }
    }


}

export default withRouter(PatientsPage)