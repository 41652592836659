import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../context/AuthContext';
import history from '../history';
import { withRouter, generatePath } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Button from 'react-bootstrap/esm/Button';
import Container from 'react-bootstrap/esm/Container';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Card } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Accordion from 'react-bootstrap/Accordion';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


const CinderellaPage = () => {
    let { authTokens, url } = useContext(AuthContext)

    const [errors, setErrors] = useState({})

    const [results, setResults] = useState([])

    const [loading, setLoading] = useState(null)

    let surgery_type
    let updateRangeValue = (field, range) => {
        if (field == "age") {
            let range1 = document.getElementById("range1")
            range1.innerHTML = range
        }
        if (field == "weight") {
            let range2 = document.getElementById("range2")
            range2.innerHTML = range
        }
        if (field == "height") {
            let range3 = document.getElementById("range3")
            range3.innerHTML = range
        }

    }

    let handleChange = (input) => {
        surgery_type = input
    }

    let handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault()
        let response = await fetch('https://breloai-backend.inesctec.pt/api/cinderella/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access
            },
            body: JSON.stringify({
                age: document.getElementById("age").value,
                weight: document.getElementById("weight").value,
                height: document.getElementById("height").value,
                bra_size: document.getElementById("bra-size").value,
                bra_cup: document.getElementById("bra-cup").value,
                surgery_type: document.getElementById("surgery-type").value,
            })
        })
        let data = await response.json()
        if (response.status === 200) {
            setResults(data)
            setLoading(false)
        }
        else if (response.status === 400 && data.age) {
            setErrors({ age: data.age })
        }
        else if (response.status === 400 && data.weight) {
            setErrors({ weight: data.weight })
        }
        else if (response.status === 400 && data.height) {
            setErrors({ height: data.height })
        }
        else if (response.status === 400 && data.bra_size) {
            setErrors({ bra_size: data.bra_size })
        }
        else if (response.status === 400 && data.bra_cup) {
            setErrors({ bra_cup: data.bra_cup })
        }
        else if (response.status === 400 && data.surgery_type) {
            setErrors({ surgery_type: data.surgery_type })
        }
    }

    let getClassificationText = (classification) => {
        if (classification === null) {
            return (
                <div>
                    <h5>Unknown</h5>
                </div>
            )
        }
        else if (classification === 1) {
            return (
                <div>
                    <h5>Excellent</h5>
                </div>
            )
        }
        else if (classification === 2) {
            return (
                <div>
                    <h5>Good</h5>
                </div>
            )
        }
        else if (classification === 3) {
            return (
                <div>
                    <h5>Fair</h5>
                </div>
            )
        }
        else if (classification === 4) {
            return (
                <div>
                    <h5>Poor</h5>
                </div>
            )
        }
    }

    return (
        <Container>
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => history.push(generatePath("/"))}>Patients</Breadcrumb.Item>
                <Breadcrumb.Item active>
                    BreloAI Page
                </Breadcrumb.Item>
            </Breadcrumb>

            <Container className='mb-4'>
                <h1 className="h2 mt-3 mb-5 text-center">BreloAI Application</h1>
                <p className="mb-4 h5">Please fill the form to find a matching case</p>

                <Form onSubmit={handleSubmit}>
                    <Form.Group className='mb-5'>
                        <Row>
                            <Col className='d-flex'>
                                <Form.Label className='px-1'>Age (years)</Form.Label>
                                <Form.Control style={{ width: "80%", height: "90%" }} className="mt-0" type='number' id="age" placeholder='1' min='1' max="150" isInvalid={!!errors.age}></Form.Control>
                                <Form.Control.Feedback type="invalid">{errors.age}</Form.Control.Feedback>
                            </Col>
                            <Col className='d-flex'>
                                <Form.Label className='px-1'>Weight (kg)</Form.Label>
                                <Form.Control style={{ width: "80%", height: "90%" }} className="mt-0" type='number' id="weight" placeholder='1' min='1' max="500" isInvalid={!!errors.weight}></Form.Control>
                                <Form.Control.Feedback type="invalid">{errors.weight}</Form.Control.Feedback>
                            </Col>
                            <Col className='d-flex'>
                                <Form.Label className='px-1'>Height (cm)</Form.Label>
                                <Form.Control style={{ width: "80%", height: "90%" }} className="mt-0" type='number' id="height" placeholder='1' min='1' max="300" isInvalid={!!errors.height}></Form.Control>
                                <Form.Control.Feedback type="invalid">{errors.height}</Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className='mb-5'>
                        <Row>
                            <Col className='d-flex'>
                                <Form.Label className='px-1'>Bra Size</Form.Label>
                                <Form.Control style={{ height: "90%" }} type='text' id='bra-size' isInvalid={!!errors.bra_size}></Form.Control>
                                <Form.Control.Feedback type="invalid">{errors.bra_size}</Form.Control.Feedback>
                            </Col>
                            <Col className='d-flex'>
                                <Form.Label className='px-1'>Bra Cup</Form.Label>
                                <Form.Control style={{ height: "90%" }} type='text' id='bra-cup' isInvalid={!!errors.bra_cup}></Form.Control>
                                <Form.Control.Feedback type="invalid">{errors.bra_cup}</Form.Control.Feedback>
                            </Col>
                        </Row>
                        <span className='px-2'>Please note that Cinderella uses the European convention for bra sizes.</span>
                    </Form.Group>

                    <Form.Group className='d-flex mb-4'>
                        <Form.Label className='px-1'>Surgery Type</Form.Label>
                        <Form.Select style={{ height: "90%" }} id="surgery-type" defaultValue="null" onChange={(e) => handleChange(e.target.value)} isInvalid={!!errors.surgery_type}>
                            <option value="">---------</option>
                            <option value="1">C1 - Conservative Surgery</option>
                            <option value="2">C2 - Conservative Surgery with bilateral reduction</option>
                            <option value="3">C3 - Conservative Surgery with LD or LICAC/TDAP flaps</option>
                            <option value="4">C4 - Conservative Surgery with bilateral implants</option>
                            <option value="5">M1 - Mastectomy with unilateral reconstruction with implant</option>
                            <option value="6">M2 - Mastectomy with unilateral reconstruction with autologous flap</option>
                            <option value="7">M3 - Mastectomy with bilateral reconstruction with implants</option>
                            <option value="8">M4 - Mastectomy with bilateral reconstruction with autologous flaps</option>
                            <option value="9">M5 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with implant (augmentation)</option>
                            <option value="10">M6 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with reduction</option>
                            <option value="11">M7 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with reduction</option>
                            <option value="12">M8 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with implant (augmentation)</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.surgery_type}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className='text-center mb-4'>
                        <Button className="OutlineButton" variant="primary" type="submit">Search</Button>
                    </Form.Group>
                </Form>

                {
                    loading === true ?
                        <Container className='text-center mb-4'>
                            <div>
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        </Container>
                        :
                        results.length !== 0 ?
                            <Accordion defaultActiveKey={['0', '1']} alwaysOpen>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header><h3>Excellent & Good</h3></Accordion.Header>
                                    <Accordion.Body>
                                        {
                                            results['exc_good'].length === 0 ?
                                                <h4 className='text-center mb-4'>No results found for Excellent and Good classifications.</h4>
                                                :
                                                <Row xs={1} md={4} className='my-3 justify-content-center'>
                                                    {
                                                        results['exc_good'].map(patient => {
                                                            return (
                                                                <Card key={patient.id} className='text-center mx-4'>
                                                                    <Card.Header className="text-center card-header-text" as="h5">
                                                                        <span style={{ 'display': 'inline-flex' }}>
                                                                            {patient.classification_type === null ?
                                                                                null
                                                                                :
                                                                                patient.classification_type === 1 ?
                                                                                    <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Subjective Classification</Tooltip>}>
                                                                                        <i className="fas fa-circle mt-1 mx-1" aria-hidden="true" style={{ color: 'red' }} />
                                                                                    </OverlayTrigger>
                                                                                    :
                                                                                    <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Objective Classification</Tooltip>}>
                                                                                        <i className="fas fa-circle mt-1 mx-1" aria-hidden="true" style={{ color: 'blue' }} />
                                                                                    </OverlayTrigger>
                                                                            }
                                                                            {getClassificationText(patient.classification)}
                                                                        </span>
                                                                    </Card.Header>
                                                                    <Card.Body>
                                                                        <Card.Img variant="top" src={'https://breloai-backend.inesctec.pt' + patient.image} width="auto" height="auto"></Card.Img>
                                                                        <Card.Title>{patient.institutional_number}</Card.Title>
                                                                        <Button className='FilledButton' onClick={() => { history.push({ pathname: generatePath("/patient/:id", { id: patient.id }), state: { id: patient.id } }) }}>Patient Page <i className="fa-solid fa-eye" aria-hidden="true"></i></Button>
                                                                    </Card.Body>
                                                                </Card>
                                                            )
                                                        })
                                                    }
                                                </Row>
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><h3>Fair & Poor</h3></Accordion.Header>
                                    <Accordion.Body>
                                        {
                                            results['fair_poor'].length === 0 ?
                                                <h4 className='text-center mb-4'>No results found for Fair and Poor classifications.</h4>
                                                :
                                                <Row xs={1} md={4} className='my-3 justify-content-center'>
                                                    {
                                                        results['fair_poor'].map(patient => {
                                                            return (
                                                                <Card key={patient.id} className='text-center mx-4'>
                                                                    <Card.Header className="text-center card-header-text" as="h5">
                                                                        <span style={{ 'display': 'inline-flex' }}>
                                                                            {patient.classification_type === null ?
                                                                                null
                                                                                :
                                                                                patient.classification_type === 1 ?
                                                                                    <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Subjective Classification</Tooltip>}>
                                                                                        <i className="fas fa-circle mt-1 mx-1" aria-hidden="true" style={{ color: 'red' }} />
                                                                                    </OverlayTrigger>
                                                                                    :
                                                                                    <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Objective Classification</Tooltip>}>
                                                                                        <i className="fas fa-circle mt-1 mx-1" aria-hidden="true" style={{ color: 'blue' }} />
                                                                                    </OverlayTrigger>
                                                                            }
                                                                            {getClassificationText(patient.classification)}
                                                                        </span>
                                                                    </Card.Header>
                                                                    <Card.Body>
                                                                        <Card.Img variant="top" src={'https://breloai-backend.inesctec.pt' + patient.image} width="auto" height="auto"></Card.Img>
                                                                        <Card.Title>{patient.institutional_number}</Card.Title>
                                                                        <Button className='FilledButton' onClick={() => { history.push({ pathname: generatePath("/patient/:id", { id: patient.id }), state: { id: patient.id } }) }}>Patient Page <i className="fa-solid fa-eye" aria-hidden="true"></i></Button>
                                                                    </Card.Body>
                                                                </Card>
                                                            )
                                                        })
                                                    }
                                                </Row>
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            :
                            <h3 className='text-center mb-4'>No results found.</h3>
                }

            </Container >
        </Container>

    )
}

export default withRouter(CinderellaPage)